// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Line Service =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class LineService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/lines/`;
  // private baseUrl: String = `${environment.devUrlV2}/lines/`;

  countries: Set<string> = new Set<string>([]);

  // #=========== ---- =========== Line Service constructor =========== ---- ===========# //
  constructor(
    public http: HttpClient,
    public authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Line Service Methods =========== ---- ===========# //

  // #=========== ---- =========== Create Line =========== ---- ===========# //
  createLine(line: any): Observable<any> {
    const token = this.authService.getToken();
    let { name, country, productsIds } = line;

    return this.http.post(
      `${this.baseUrl}create`,
      { name, country, productsIds },
      {
        headers: { authorization: "Bearer " + token },
      }
    );
  }

  // #=========== ---- =========== Get Lines =========== ---- ===========# //
  getLines(): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(`${this.baseUrl}company`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Get Line =========== ---- ===========# //
  getLine(lineId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(`${this.baseUrl}get/${lineId}`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Update Line =========== ---- ===========# //
  updateLine(lineId: string, line: any): Observable<any> {
    const token = this.authService.getToken();
    let { name, country, productsIds } = line;
    return this.http.put(
      `${this.baseUrl}update/${lineId}`,
      { name, country, productsIds },
      {
        headers: { authorization: "Bearer " + token },
      }
    );
  }

  assignProductsToLine(lineId: string, productsIds: any): Observable<any> {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .put(
        this.baseUrl + "/assign-products/" + lineId,
        { productsIds },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.line;
          } else {
            return null;
          }
        })
      );
  }

  assignProductToLines(productId: string, lineIds: any): Observable<any> {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http
      .put(
        this.baseUrl + "/assign-product-to-lines/" + companyId,
        { lineIds, productId },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.lines;
          } else {
            return null;
          }
        })
      );
  }

  // #=========== ---- =========== Delete Line =========== ---- ===========# //
  deleteLine(lineId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.delete(`${this.baseUrl}delete/${lineId}`, {
      headers: { authorization: "Bearer " + token },
    });
  }
}
