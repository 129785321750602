// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

// #=========== ---- =========== Import Libraries =========== ---- ===========# //
import * as moment from 'moment';

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { TicketService } from "../../services/ticket.service";
import { AuthenticationService } from "../../services/authentication.service";


// #=========== ---- =========== Ticket Component Decorator =========== ---- ===========# //
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})

// #=========== ---- =========== Ticket Component =========== ---- ===========# //
export class TicketComponent implements OnInit {
  // Ticket Data
  ticketId: string;
  ticket: any;

  // User Data
  user: any;

  // Form Data
  message = {
    from: "Customer Service",
    content: "",
  };

  @ViewChild("file_input") fileInput: any;
  filesToUpload: Array<File> = [];

  // Modal Data
  @ViewChild("modal_img")  modalImg: any;
  @ViewChild("caption") captionText: any;
  @ViewChild("modal") modal: any;

  
  // @ts-ignore
  mediaRecorder: any;

  // #=========== ---- =========== Customer-Service Component Constructor =========== ---- ===========# //
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    public ticketService: TicketService,
  ) {};

  // #=========== ---- =========== On Init =========== ---- ===========# //

  ngOnInit() {
    this.authService.isLoggedInWithRedirect();

    this.ticketId = this.route.snapshot.params.ticketId;
    this.ticketService
      .getTicket(this.ticketId)
      .subscribe((ticket: any) => {
        if (ticket) {
          this.ticket = ticket;
        } else {
          this.snackBar.open("Ticket not found", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
          this.router.navigateByUrl("/customer-service");
        }
      })
  }

  // #=========== ---- =========== Close Ticket =========== ---- ===========# //
  closeTicket() {
    this.ticketService
      .changeTicketStatus(this.ticket._id, "CLOSED")
      .subscribe((data) => {
        if (data.message === "success") {
          this.ticket.status = "CLOSED";
          
          this.snackBar.open("Ticket Closed", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });

          this.router.navigateByUrl("/customer-service");
        } else {
          this.snackBar.open("Error closing ticket", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
        }
      })
  }
  
  // #=========== ---- =========== Handle File Input =========== ---- ===========# //
  handleFileInput(fileInput: any) {
    this.filesToUpload = <Array<File>>fileInput.target.files;
  }

  // #=========== ---- =========== Close Ticket =========== ---- ===========# //
  sendMessage() {
    const messageData = new FormData();

    this.filesToUpload.forEach((file, index) => {
      messageData.append('images', file, file.name);
    });

    messageData.append('from', this.message.from);
    messageData.append('content', this.message.content);

    let date = new Date();
    const dateTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
    messageData.append('date_time', dateTime);

    this.ticketService
      .sendMessage(this.ticketId, messageData)
      .subscribe((data) => {
        if (data.message === "success") {
          this.ticket.messages.push({
            content: this.message.content,
            from: this.message.from,
            date_time: dateTime,
          });
          this.message.content = "";
        } else {
          this.snackBar.open("Error sending message", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
        }
      })
  }
 
  // #=========== ---- =========== Format DateTime =========== ---- ===========# // 
  formatDateTime(dateTime: any)
  {
    return moment(dateTime).format("dddd, MMMM Do YYYY, h:mm:ss a");
  }

  openModal(event: any): void
  {
    this.modal.style.display = "block";
    this.modalImg.src = event.srcElement.src;
    this.captionText.innerHTML = event.srcElement.alt;
  }

  closeModal(): void
  {
    this.modal.style.display = "none";
  }

  // recordAudio(): void
  // {
  //   console.log('RECORDING')
  //   navigator.mediaDevices.getUserMedia({ audio: true })
  //   .then(stream => {
  //     // @ts-ignore
  //     this.mediaRecorder = new MediaRecorder(stream);
  //     this.mediaRecorder.start();

  //     const audioChunks = [];

  //     this.mediaRecorder.addEventListener("dataavailable", event => {
  //       audioChunks.push(event.data);
  //     });

  //     this.mediaRecorder.addEventListener("stop", () => {
  //       let audioBlob = new Blob(audioChunks, {type: 'audio/mpeg'});
  //       //audioBlob.filename = "audio.mp3";


  //       const audioUrl = URL.createObjectURL(audioBlob);
  //       console.log(audioBlob);
  //       const audio = new Audio(audioUrl);
  //       this.ticketService.sendVoiceNote(this.ticket._id, this.message, audioBlob, this.ticket.user.user_id).subscribe(data => {
  //         this.ticket = data.response;
  //       })
  //       audio.play();
  //     });

  //   });
  // }

  // stopRecording(): void
  // {
  //   console.log('STOPPED RECORDING');
  //   this.mediaRecorder.stop();
  //   this.snackBar.open("Recording Stopped", "Close", {
  //     verticalPosition: "top",
  //     duration: 2000,
  //   });
  // }
}
