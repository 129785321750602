// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

// #=========== ---- =========== Import External Libraries =========== ---- ===========# //
import { Observable, throwError } from "rxjs";
import { tap } from "rxjs/operators";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "src/environments/environment";

// #=========== ---- =========== Authentication Service Decorator =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})

// #=========== ---- =========== Authentication Service =========== ---- ===========# //
export class AuthenticationService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/admins/`;
  private key: string = "tacitapp-cPanel";

  // #=========== ---- =========== Authentication Constructor =========== ---- ===========# //
  constructor(private http: HttpClient, private router: Router) {}

  // #=========== ---- =========== Login Method =========== ---- ===========# //
  public login(email: string, password: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}login`, {
        email,
        password,
      })
      .pipe(
        tap((data: any) => {
          data.res.companyName = email.split("@")[1];
          this.saveSession(data.res);
        })
      );
  }

  // #=========== ---- =========== Logout Method =========== ---- ===========# //
  public logout(): void {
    window.sessionStorage.removeItem(this.key);
    this.router.navigateByUrl("/login");
  }

  // #=========== ---- =========== isLoggedIn Methods =========== ---- ===========# //
  public isLoggedIn(): boolean {
    let session = this.getSession();
    if (session == null || session.token == null || session.token == "") {
      return false;
    }
    return true;
  }

  public isLoggedInWithRedirect(): boolean {
    let logged = this.isLoggedIn();
    if (!logged) {
      this.router.navigateByUrl("/login");
    }
    return logged;
  }

  // #=========== ---- =========== saveSession Method =========== ---- ===========# //
  public saveSession(dataObj: any) {
    window.sessionStorage.setItem(this.key, JSON.stringify(dataObj));
  }

  // #=========== ---- =========== Getters =========== ---- ===========# //
  public getSession(): any {
    return JSON.parse(window.sessionStorage.getItem(this.key));
  }

  public getCompanyId(): any {
    return this.getSession().company._id;
  }

  public getUser(): any {
    return this.getSession().admin;
  }

  public getToken(): any {
    return this.getSession().token;
  }

  public getUsers(): any {
    return this.getSession().admin.users;
  }

  public getCompanyName(): any {
    return this.getSession().companyName;
  }
}
