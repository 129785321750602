// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { LeaveManagementTemplateService } from "src/app/services/leave-management-template.service";
import { max } from "moment";

// #=========== ---- =========== Edit Leave Management Template Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-edit-leave-management-template",
  templateUrl: "./edit-leave-management-template.component.html",
  styleUrls: ["./edit-leave-management-template.component.css"],
})

// #=========== ---- =========== Edit Leave Management Template Component =========== ---- ===========# //
export class EditLeaveManagementTemplateComponent implements OnInit {
    leaveManagementTemplateForm: FormGroup;
    leaveManagementTemplate: any;

    validLeaveTypes: string[];
    noDaysLeaveTypes: string[];
    allLeaveTypes: string[];

    weekdays = {
        "0": "Sunday",
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday",
    }

    // #=========== ---- =========== Edit Leave Management Template Component Constructor =========== ---- ===========# //
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private leaveManagementTemplateService: LeaveManagementTemplateService,
    ) {}

    // #=========== ---- =========== Edit Leave Management Template Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authenticationService.isLoggedInWithRedirect();
        
        const session = this.authenticationService.getSession();
        this.validLeaveTypes = session.company.leaveTypes;
        this.noDaysLeaveTypes = session.company.noDaysLeaveTypes;
        this.allLeaveTypes = this.validLeaveTypes.concat(this.noDaysLeaveTypes);

        this.leaveManagementTemplateForm = this.formBuilder.group({
            category: ["", Validators.required],

            leaves: this.formBuilder.array([]),

            weekend: this.formBuilder.array(
                Array(7).fill(false).map(() => this.formBuilder.control(false))
            ),
            maxCausalLeavePerMonth: ["", Validators.required],
        });

        const leaveManagementTemplateId = this.route.snapshot.paramMap.get("leaveManagementTemplateId");

        this.leaveManagementTemplateService
            .getLeaveManagementTemplate(leaveManagementTemplateId)
            .subscribe(
                (data: any) => {
                    if (data.message == "success") {
                        this.leaveManagementTemplate = data.res.leaveManagementTemplate;

                        this.leaveManagementTemplateForm.patchValue({
                            category: this.leaveManagementTemplate.category,
                            maxCausalLeavePerMonth: this.leaveManagementTemplate.maxCausalLeavePerMonth || 0,
                        });

                        this.leaveManagementTemplate.leaves.forEach((leave: any) => {
                            // Checking if the leave type requires HR or Supervisor approval
                            const hrApproval = this.leaveManagementTemplate.hrApprovals.includes(leave.type);
                            const supervisorApproval = this.leaveManagementTemplate.supervisorApprovals.includes(leave.type);

                            this.addLeaveGroup(leave.type, leave.days, hrApproval, supervisorApproval);
                        });

                        this.leaveManagementTemplate.weekend.forEach((day: number) => {
                            const weekendArray = this.leaveManagementTemplateForm.get("weekend") as FormArray;
                            weekendArray.at(day).setValue(true);
                        });
                    } else {
                        this.snackBar.open("Error fetching leave management template", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });

                        this.router.navigate(["/leave-management-template"]);
                    }
                },
                (error) => {
                    this.snackBar.open("Error fetching leave management template", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });

                    this.router.navigate(["/leave-management-template"]);
                }
            );
    }

    // #=========== ---- =========== Form Arrays Helper Methods =========== ---- ===========# //
    createLeaveGroup(
        type: string,
        days: string,
        hrApproval: boolean,
        supervisorApproval: boolean
    ): FormGroup {
        const leaveGroup = this.formBuilder.group({
            leaveType: [type, Validators.required],
            leaveDays: [
                { value: days, disabled: this.noDaysLeaveTypes.includes(type) },
                this.noDaysLeaveTypes.includes(type) ? null : [Validators.required, Validators.pattern("^[0-9]*$")],
            ],
            hrApproval: [hrApproval, Validators.required],
            supervisorApproval: [supervisorApproval, Validators.required],
        });

        leaveGroup.get("leaveType").valueChanges.subscribe((value: string) => {
            const leaveDaysControl = leaveGroup.get("leaveDays");

            if (this.noDaysLeaveTypes.includes(value)) {
                leaveDaysControl.disable();
                leaveDaysControl.clearValidators();
            } else {
                leaveDaysControl.enable();
                leaveDaysControl.setValidators([
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                ]);
            }

            leaveDaysControl.updateValueAndValidity();
        });

        return leaveGroup;
    }

    addLeaveGroup(
        // Added default values to the parameters to call it in the html template without passing arguments
        type: string = "",
        days: string = "",
        hrApproval: boolean = false,
        supervisorApproval: boolean = false
    ): void {
        const leaveGroup = this.createLeaveGroup(type, days, hrApproval, supervisorApproval);
        const leaveArray = this.leaveManagementTemplateForm.get("leaves") as FormArray;
        leaveArray.push(leaveGroup);
    }

    getLeaveGroups(): FormArray {
        return this.leaveManagementTemplateForm.get("leaves") as FormArray;
    }

    // #=========== ---- =========== Edit Leave Management Template Component onDeleteLeaveGroup =========== ---- ===========# //
    onDeleteLeaveGroup(index: number): void {
        const leaveArray = this.leaveManagementTemplateForm.get("leaves") as FormArray;
        leaveArray.removeAt(index);
    }

    // #=========== ---- =========== Edit Leave Management Template Component getWeekendData =========== ---- ===========# //
    getWeekendData(): number[] {
        const weekendArray = this.leaveManagementTemplateForm.get("weekend") as FormArray;
        
        const weekendDays = [];
        weekendArray.value.forEach((day: boolean, index: number) => {
            day && weekendDays.push(index);
        });

        return weekendDays;
    }

    // #=========== ---- =========== Edit Leave Management Template Component onSubmit =========== ---- ===========# //
    onSubmit(): void {
        if (this.leaveManagementTemplateForm.invalid) {
            this.snackBar.open("Please fill in all required fields", "Close", {
                verticalPosition: "top",
                duration: 2000,
            });

            return;
        }

        const leaveManagementTemplateId = this.route.snapshot.paramMap.get("leaveManagementTemplateId");
        const countryId = this.leaveManagementTemplate.countryId._id;

        const formData = {};
        formData["category"] = this.leaveManagementTemplateForm.value.category;
        formData["maxCausalLeavePerMonth"] = Number(this.leaveManagementTemplateForm.value.maxCausalLeavePerMonth);

        const leaves = [];
        const hrApprovals = [];
        const supervisorApprovals = [];

        this.getLeaveGroups().value.forEach((leave: any) => {
            const leaveObj: any = {
                type: leave.leaveType,
            };
        
            if (!this.noDaysLeaveTypes.includes(leave.leaveType)) {
                leaveObj.days = Number(leave.leaveDays);
            }
        
            leaves.push(leaveObj);

            if (leave.hrApproval) {
                hrApprovals.push(leave.leaveType);
            }

            if (leave.supervisorApproval) {
                supervisorApprovals.push(leave.leaveType);
            }
        });

        formData["leaves"] = leaves;
        formData["hrApprovals"] = hrApprovals;
        formData["supervisorApprovals"] = supervisorApprovals;
        formData["weekend"] = this.getWeekendData();

        this.leaveManagementTemplateService
            .editLeaveManagementTemplate(countryId, leaveManagementTemplateId, formData)
            .subscribe(
                (data: any) => {
                    if (data.message == "success") {
                        this.snackBar.open("Leave management template updated successfully", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });

                        this.router.navigate(["/leave-management-templates"]);
                    } else {
                        this.snackBar.open("Error updating leave management template", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }
                },
                (error) => {
                    this.snackBar.open("Error updating leave management template", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });
                }
            );
    }

    // #=========== ---- =========== Edit Leave Management Template Component onDelete =========== ---- ===========# //
    onDelete(): void {
        const leaveManagementTemplateId = this.route.snapshot.paramMap.get("leaveManagementTemplateId");

        this.leaveManagementTemplateService.deleteLeaveManagementTemplate(leaveManagementTemplateId).subscribe(
            (data: any) => {
                if (data.message == "success") {
                    this.snackBar.open("Leave management template deleted successfully", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });

                    this.router.navigate(["/leave-management-templates"]);
                } else {
                    this.snackBar.open("Error deleting leave management template", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });
                }
            },
            (error) => {
                this.snackBar.open("Error deleting leave management template", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });
            }
        );
    }
}