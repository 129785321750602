// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import {
  MatSnackBar,
  MatTableDataSource,
} from "@angular/material";

import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { UserPolicyService } from "../services/user-policy.service";

// #=========== ---- =========== User Policies Component Decorator =========== ---- ===========# //
@Component({
    selector: "app-user-policies",
    templateUrl: "./user-policies.component.html",
    styleUrls: ["./user-policies.component.css"],
    animations: [
        trigger("detailExpand", [
            state(
            "collapsed",
            style({ height: "0px", minHeight: "0", display: "none" })
            ),
            state("expanded", style({ height: "*" })),
            transition(
            "expanded <=> collapsed",
            animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})

// #=========== ---- =========== User Policies Component =========== ---- ===========# //
export class UserPoliciesComponent implements OnInit {
    // Filter variables
    filter: any = {};
    roles: string[];
    modules: string[];

    // Table variables
    userPolicies: any[];
    userPoliciesDataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ["role"];
    expandedElement: any | null;

    // #=========== ---- =========== User Policies Component constructor =========== ---- ===========# //
    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private authService: AuthenticationService,
        private userPolicyService: UserPolicyService,
    ) {}

    // #=========== ---- =========== User Policies Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authService.isLoggedInWithRedirect();

        const session = this.authService.getSession();
        this.roles = session.company.roles;
        this.modules = session.company.modules;

        this.userPolicyService.getCompanyUserPolicies().subscribe(
            (data) => {
                if (data.message === "success") {
                    this.userPolicies = data.res.companyUserPolicies;
                    
                    this.userPoliciesDataSource = new MatTableDataSource(this.userPolicies);
                    this.userPoliciesDataSource.filterPredicate = (data: any, filter: string) => {
                        const isRoleMatch = data.role.toLowerCase().includes(filter);
                        const isModuleMatch = data.menuItems.some(
                            (menuItem: any) => menuItem.toLowerCase().includes(filter)
                        );

                        return isRoleMatch || isModuleMatch;
                    }
                }
            },
            (error) => {
                this.snackBar.open("Error fetching User Policies", "Close", {
                    verticalPosition: "top",
                    duration: 3000,
                });
            }
        );
    }

    // #=========== ---- =========== Apply Filter Method =========== ---- ===========# //
    applyFilter() {
        let filteringData = this.userPolicies;

        for (let attr in this.filter) {
            if (this.filter[attr] !== undefined) {
                if (attr === "role") {
                    filteringData = filteringData.filter((userPolicy: any) => {
                        return userPolicy.role === this.filter[attr];
                    });
                } else if (attr === "module") {
                    filteringData = filteringData.filter((userPolicy: any) => {
                        return userPolicy.menuItems.includes(this.filter[attr]);
                    });
                }
            }
        }

        this.userPoliciesDataSource = new MatTableDataSource(filteringData);
    }

    // #=========== ---- =========== Search Method =========== ---- ===========# //
    search(searchValue: string) {
        this.userPoliciesDataSource.filter = searchValue.trim().toLowerCase();
    }

    // #=========== ---- =========== Handle Edit User Policy =========== ---- ===========# //
    handleEditUserPolicy(userPolicy: any) {
        this.router.navigateByUrl(`/user-policies/${userPolicy.role}`);
    }

    // #=========== ---- =========== Handle Create User Policy =========== ---- ===========# //
    handleCreateUserPolicy() {
        this.router.navigateByUrl("/user-policies/create");
    }
}