// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar, MatTableDataSource } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { LineService } from "../services/line.service";
import { PrintService } from "../services/print.service";

// #=========== ---- =========== Lines Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-lines",
  templateUrl: "./lines.component.html",
  styleUrls: ["./lines.component.css"],
})

// #=========== ---- =========== Lines Component =========== ---- ===========# //
export class LinesComponent implements OnInit {
  // filter variables
  filter: any = {};
  countries: string[] = [];

  // table variables
  lines: any = [];
  linesDataSource: MatTableDataSource<any>;
  displayedColumns = ["#", "name", "country"];

  // #=========== ---- =========== Lines Component Constructor =========== ---- ===========# //
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private lineService: LineService,
    private printService: PrintService
  ) {}

  // #=========== ---- =========== Lines Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    // checking if user is logged in
    this.authenticationService.isLoggedInWithRedirect();

    this.lineService.getLines().subscribe((data: any) => {
      if (data.message == "success") {
        this.lines = data.res.lines;
        this.lines.forEach((line: any) => {
          if (!this.countries.includes(line.country)) {
            this.countries.push(line.country);
          }
        });
        this.linesDataSource = new MatTableDataSource(this.lines);
      }
    });
  }

  // #=========== ---- =========== Lines Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Apply Filter Method =========== ---- ===========# //

  applyFilter() {
    let filteringData = this.lines;

    for (let attr in this.filter) {
      if (this.filter[attr] != undefined) {
        if (attr == "country") {
          filteringData = filteringData.filter(
            (line) => line.country == this.filter[attr]
          );
        }
      }
    }

    this.linesDataSource = new MatTableDataSource(filteringData);
  }

  // #=========== ---- =========== Search Method =========== ---- ===========# //

  search(filterValue: string) {
    this.linesDataSource.filter = filterValue.trim().toLowerCase();
  }

  // #=========== ---- =========== Print Method =========== ---- ===========# //
  print(): void {
    const companyName = this.authenticationService.getCompanyName();

    let orientation = "portrait";
    let reportCols = [
      { title: "Line", dataKey: "name" },
      { title: "Country", dataKey: "country" },
      { title: "Products", dataKey: "products" },
    ];
    let reportHeader = `${companyName} Lines Report`;
    let filename = `${companyName}-lines-report.pdf`;

    const linesToPrint = this.linesDataSource.filteredData.map((line: any) => {
      return {
        name: line.name,
        country: line.country,
        products: line.products.map((product: any) => product.name).join("\n"),
      };
    });

    this.printService.printTable(
      orientation,
      reportCols,
      linesToPrint,
      reportHeader,
      filename
    );
  }

  // #=========== ---- =========== Handle Line CLick Method =========== ---- ===========# //
  handleLineCLick(line: any): void {
    this.router.navigateByUrl("/lines/" + line._id);
  }

  // #=========== ---- =========== Handle Create Line CLick Method =========== ---- ===========# //
  handleCreateLineClick(): void {
    this.router.navigateByUrl("/lines/create");
  }
}
