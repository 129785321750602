import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";

import { AuthenticationService } from '../services/authentication.service';
import { ProductService } from '../services/product.service';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  animations: [
      trigger("detailExpand", [
          state(
          "collapsed",
          style({ height: "0px", minHeight: "0", display: "none" })
          ),
          state("expanded", style({ height: "*" })),
          transition(
          "expanded <=> collapsed",
          animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
          ),
      ]),
  ],
})

export class ProductsComponent implements OnInit {
  uploadUrl = this.productService.baseUploadsUrl;
  products: any[];
  filter: any = {};

  catalogues: Set<string> = new Set();
  expandedElement: any | null;
  
  displayedColumns = [
    '#',
    'name',
    'type',
    'catalogue',
    'isCatalogue',
  ];
  productsDisplayed: MatTableDataSource<any>;

  constructor(
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  preprocess_products = (products: any[]) => {
    products = products.map((product, index) => {
      if (product.price !== 0) {
        product.type = 'sales'
      } else {
        product.type = 'marketing'
      }

      product.latestVersion = product.versions.pop();
      product.index = index + 1; 
      this.catalogues.add(product.catalogue);
      return product;
    });

    return products;
  }
  
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();
    
    this.productService
      .getCompanyProducts()
      .subscribe((data: any) => {
        if (data.message === "success") {
          this.products = data.res.products;
          this.products = this.preprocess_products(this.products);
          this.productsDisplayed = new MatTableDataSource(this.products);
          this.productsDisplayed.filterPredicate = this.filterPredicate;
        } else {
          this.snackBar.open('Failed to get products', 'Close', {
            duration: 5000,
            verticalPosition: 'top',
          });
        }
      });
  }

  filterPredicate = (data: any, filter: string) => {
    const isNameMatch = data.name.toLowerCase().includes(filter);
    const isTypeMatch = data.type.toLowerCase().includes(filter);
    const catalogueMatch = data.catalogue.toLowerCase().includes(filter);
    const isCatalogueMatch = String(data.isCatalogue).toLowerCase().includes(filter);

    return isTypeMatch || catalogueMatch || isCatalogueMatch || isNameMatch;
  }

  applyFilter() {
    let filteringData = this.products;

    for(let attr in this.filter) {
      if (this.filter[attr] === undefined) {
        continue;
      }
      filteringData = filteringData.filter(product => {
        return String(product[attr]).toLowerCase() === this.filter[attr].toLowerCase();
      });
    }

    this.productsDisplayed = new MatTableDataSource(filteringData);
  }

  search(event: any) {
    const filterValue = event.target.value.trim().toLowerCase();
    this.productsDisplayed.filter = filterValue;
  }

  goToProduct(product: any) {
    this.router.navigateByUrl(`/products/${product._id}`);
  }

  goToCreateProduct() {
    this.router.navigateByUrl('/products/create');
  }
}
