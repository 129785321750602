// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== User Policy Service =========== ---- ===========# //
@Injectable({
    providedIn: "root",
})

export class UserPolicyService {
    // TODO: Change to baseUrlV2 after publishing changes in the backend
    private baseUrl: String = `${environment.devUrlV2}/user-policies`;

    // #=========== ---- =========== User Policy Service constructor =========== ---- ===========# //
    constructor(
        public http: HttpClient,
        public authService: AuthenticationService
    ) {}

    // #=========== ---- =========== User Policy Service Methods =========== ---- ===========# //

    // #=========== ---- =========== Get Company User Policies =========== ---- ===========# //
    getCompanyUserPolicies(): Observable<any> {
        const token = this.authService.getToken();
        
        return this.http.get(`${this.baseUrl}/get/company`, {
            headers: { authorization: "Bearer " + token },
        })
    }

    // #=========== ---- =========== Get User Policy =========== ---- ===========# //
    getUserPolicy(role: string): Observable<any> {
        const token = this.authService.getToken();

        return this.http.get(`${this.baseUrl}/get/one/${role}`, {
            headers: { authorization: "Bearer " + token },
        });
    }

    // #=========== ---- =========== Update User Policy =========== ---- ===========# //
    updateUserPolicy(
        userPolicyId: string,
        userPolicyData: { role: string, menuItems: string[] }
    ): Observable<any> {
        const token = this.authService.getToken();

        return this.http.put(
            `${this.baseUrl}/update/${userPolicyId}`,
            userPolicyData,
            {
                headers: { authorization: "Bearer " + token },
            }
        );
    }

    // #=========== ---- =========== Delete User Policy =========== ---- ===========# //
    deleteUserPolicy(userPolicyId: string): Observable<any> {
        const token = this.authService.getToken();

        return this.http.delete(
            `${this.baseUrl}/delete/${userPolicyId}`,
            {
                headers: { authorization: "Bearer " + token },
            }
        );
    }

    // #=========== ---- =========== Create User Policy =========== ---- ===========# //
    createUserPolicy(userPolicyData: { role: string, menuItems: string[] }): Observable<any> {
        const token = this.authService.getToken();

        return this.http.post(
            `${this.baseUrl}/create`,
            userPolicyData,
            {
                headers: { authorization: "Bearer " + token },
            }
        );
    }
}