// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { CallExpirationService } from "src/app/services/call-expiration.service";
import { CountryService } from "src/app/services/country.service";

// #=========== ---- =========== Edit Call Expiration Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-edit-call-expiration",
  templateUrl: "./edit-call-expiration.component.html",
})

// #=========== ---- =========== Edit Call Expiration Component =========== ---- ===========# //
export class EditCallExpirationComponent implements OnInit {
    callExpirationForm: FormGroup;
    callExpiration: any;

    countries: any[];

    // #=========== ---- =========== Edit Call Expiration Component Constructor =========== ---- ===========# //
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private callExpirationService: CallExpirationService,
        private countryService: CountryService,
    ) {}

    // #=========== ---- =========== Edit Call Expiration Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authenticationService.isLoggedInWithRedirect();

        this.callExpirationForm = this.formBuilder.group({
            countryId: ["", Validators.required],
            expirationDays: ["", Validators.required],
        });

        const callExpirationId = this.route.snapshot.paramMap.get("callExpirationId");

        this.callExpirationService.getCallExpiration(callExpirationId).subscribe((callExpirationData: any) => {
            if (callExpirationData.message == "success") {
                this.callExpiration = callExpirationData.res.callExpiration;

                this.countryService.getCountries().subscribe((countryData: any) => {
                    if (countryData.message == "success") {
                        this.countries = countryData.res.countries;

                        this.callExpirationForm.patchValue({
                            countryId: this.callExpiration.countryId,
                            expirationDays: this.callExpiration.expirationDays,
                        });
                    }
                });
            } else {
                this.snackBar.open("Error fetching call expiration data", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });
            }
        });
    }

    // #=========== ---- =========== Edit Call Expiration Component onSubmit =========== ---- ===========# //
    onSubmit(): void {
        if (this.callExpirationForm.invalid) {
            this.snackBar.open("Please fill in all required fields", "Close", {
                verticalPosition: "top",
                duration: 2000,
            });
            return;
        }

        this.callExpirationService
            .updateCallExpiration(this.callExpiration._id, this.callExpirationForm.value)
            .subscribe(
                (data: any) => {
                    if (data.message == "success") {
                        this.snackBar.open("Call expiration updated successfully", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                        this.router.navigate(["/call-expirations"]);
                    } else {
                        this.snackBar.open("Error updating call expiration", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }
                },
                (error) => {
                    if (error.status === 400) {
                        this.snackBar.open("Call Expiration with this Country Already Exists, Update it instead", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    } else {
                        this.snackBar.open("Error updating call expiration, try again later", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }

                    this.router.navigate(["/call-expirations"]);
                }
            );
    }

    // #=========== ---- =========== Edit Call Expiration Component onDelete =========== ---- ===========# //
    onDelete(): void {
        this.callExpirationService.deleteCallExpiration(this.callExpiration._id).subscribe((data: any) => {
            if (data.message == "success") {
                this.snackBar.open("Call expiration deleted successfully", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });
                this.router.navigate(["/call-expirations"]);
            } else {
                this.snackBar.open("Error deleting call expiration", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });
            }
        });
    }
}