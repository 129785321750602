import { Injectable } from '@angular/core';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class FormatService 
{

  constructor() { }

  formatDateTimeToFullDateTime(datetime): string
  {
    if(datetime)
    {
      return moment(datetime).format('dddd, MMMM Do YYYY, h:mm:ss a');
    }
    return moment().format('dddd, MMMM Do YYYY, h:mm:ss a')
  }

  formatDateTimeToSimpleDateTime(datetime): string
  {
    if(datetime)
    {
      return moment(datetime).format('MM/DD/YYYY h:mm A');
    }
    return moment().format('MM/DD/YYYY h:mm A')
  }




}
