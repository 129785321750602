// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatSnackBar, MatTableDataSource } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { SpecialitiesService } from "../services/specialties.service";
import { PrintService } from "../services/print.service";
import { FormatService } from "../services/format.service";

// #=========== ---- =========== Specialities Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-specialities",
  templateUrl: "./specialties.component.html",
  styleUrls: ["./specialties.component.css"],
})

// #=========== ---- =========== Specialities Component =========== ---- ===========# //
export class SpecialtiesComponent implements OnInit {
  // table variables
  specialities: any = [];
  specialitiesDataSource: MatTableDataSource<any>;
  displayedColumns = ["#", "abbreviation", "speciality"];

  // #=========== ---- =========== Specialities Component Constructor =========== ---- ===========# //
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private specialitiesService: SpecialitiesService,
    private printService: PrintService,
    private formatService: FormatService
  ) {}

  // #=========== ---- =========== Specialities Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    // checking if user is logged in
    this.authenticationService.isLoggedInWithRedirect();

    // getting the company users
    this.specialitiesService.getSpecialties().subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.specialities = data.res.specialities;
          this.specialitiesDataSource = new MatTableDataSource<any>(
            this.specialities
          );
        }
      },
      (error) => {
        this.snackBar.open(
          "Unexpected error occurred, logout and try again",
          "Close",
          {
            verticalPosition: "top",
            duration: 2000,
          }
        );
      }
    );
  }

  // #=========== ---- =========== Specialities Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Search Method =========== ---- ===========# //
  search(filterValue: string) {
    this.specialitiesDataSource.filter = filterValue.trim().toLowerCase();
  }

  // #=========== ---- =========== Print Method =========== ---- ===========# //
  print(): void {
    const reportCols = [
      { title: "Abbreviation", dataKey: "abbreviation" },
      { title: "Specialities", dataKey: "speciality" },
    ];
    this.printService.printTable(
      "landscape",
      reportCols,
      this.specialitiesDataSource.filteredData,
      "Specialities" + this.formatService.formatDateTimeToFullDateTime(null),
      "specialities"
    );
  }

  // #=========== ---- =========== Handle Speciality Click Method =========== ---- ===========# //
  handleSpecialityClick(speciality: any): void {
    this.router.navigateByUrl("/specialities/" + speciality._id);
  }

  // #=========== ---- =========== Handle Create Speciality Click Method =========== ---- ===========# //
  handleCreateSpecialityClick(): void {
    this.router.navigateByUrl("/specialities/create");
  }
}
