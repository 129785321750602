// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Country Service =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class CountryService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/countries/`;
  countries: any[];

  // #=========== ---- =========== Country Service constructor =========== ---- ===========# //
  constructor(
    public http: HttpClient,
    public authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Country Service Methods =========== ---- ===========# //

  // #=========== ---- =========== Get Countries Method =========== ---- ===========# //
  getCountries(): Observable<any> {
    return this.http.get(`${this.baseUrl}`, {
      headers: { authorization: "Bearer " + this.authService.getToken() },
    });
  }

  // #=========== ---- =========== Process Received Countries =========== ---- ===========# //
  processCountries(countries: any, filter = ""): any {
    let filteringData = countries;
    filteringData = filteringData.filter((country) => country.name == filter);

    const countriesNames = countries.map((country: any) => country.name);

    const provinces = filteringData.flatMap((country: any) =>
      country.provinces.map((province: any) => province.name)
    );

    const cities = filteringData.flatMap((country: any) =>
      country.provinces.flatMap((province: any) =>
        province.cities.map((city: any) => city.name)
      )
    );

    countriesNames.unshift("No Country");
    let workingProvinces = provinces.map((p) => {
      return { name: p, selected: false };
    });
    provinces.unshift("No Province");
    cities.unshift("No City");
    // Remove duplicates
    const uniqueCountries = new Set(countriesNames);
    const uniqueProvinces = new Set(provinces);
    const uniqueCities = new Set(cities);

    return {
      countries: uniqueCountries,
      provinces: uniqueProvinces,
      workingProvinces,
      cities: uniqueCities,
    };
  }

  // #=========== ---- =========== Get Country Provinces =========== ---- ===========# //
  getCountryProvinces(country: string): any {
    const selectedCountry = this.countries.find((c: any) => c.name === country);
    if (!selectedCountry) {
      return [];
    }
    const provinces = selectedCountry.provinces.map(
      (province: any) => province.name
    );
    return provinces;
  }

  // #=========== ---- =========== Get Province Cities =========== ---- ===========# //
  getProvinceCities(country: string, province: string): any {
    const selectedCountry = this.countries.find((c: any) => c.name === country);
    if (!selectedCountry) {
      return [];
    }
    const selectedProvince = selectedCountry.provinces.find(
      (p: any) => p.name === province
    );
    if (!selectedProvince) {
      return [];
    }
    const cities = selectedProvince.cities.map((city: any) => city.name);
    return cities;
  }

  // #=========== ---- =========== Create Country =========== ---- ===========# //
  createCountry(country: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http
      .post(
        `${this.baseUrl}create-country`,
        { country },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.country;
          } else {
            return null;
          }
        })
      );
  }

  // #=========== ---- =========== Create Province =========== ---- ===========# //
  createProvince(province: string, countryId: string): Observable<any> {
    const token = this.authService.getToken();
    return this.http
      .post(
        `${this.baseUrl}create-province/${countryId}`,
        { province },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.country;
          } else {
            return null;
          }
        })
      );
  }

  // #=========== ---- =========== Create Cities =========== ---- ===========# //
  createCities(
    cities: any,
    province: string,
    countryId: string
  ): Observable<any> {
    const token = this.authService.getToken();

    return this.http
      .post(
        `${this.baseUrl}create-cities/${countryId}`,
        {
          province,
          cities,
        },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.country;
          } else {
            return null;
          }
        })
      );
  }

  // #=========== ---- =========== Delete Country =========== ---- ===========# //
  deleteCountry(countryId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http
      .delete(`${this.baseUrl}delete/${countryId}`, {
        headers: { authorization: "Bearer " + token },
      })
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.country;
          } else {
            return null;
          }
        })
      );
  }

  // #=========== ---- =========== Delete Province =========== ---- ===========# //
  deleteProvince(province: string, countryId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http
      .post(
        `${this.baseUrl}delete-province/${countryId}`,
        { province },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.country;
          } else {
            return null;
          }
        })
      );
  }

  // #=========== ---- =========== Delete City =========== ---- ===========# //
  deleteCity(
    city: string,
    province: string,
    countryId: string
  ): Observable<any> {
    const token = this.authService.getToken();

    return this.http
      .post(
        `${this.baseUrl}delete-city/${countryId}`,
        {
          province,
          city,
        },
        { headers: { authorization: "Bearer " + token } }
      )
      .pipe(
        map((data: any) => {
          if (data.message === "success") {
            return data.res.country;
          } else {
            return null;
          }
        })
      );
  }
}
