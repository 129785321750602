// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  MatSnackBar,
  MatTableDataSource,
} from "@angular/material";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { CompanyService } from "../services/company.service";

// #=========== ---- =========== Companies Component Decorator =========== ---- ===========# //
@Component({
    selector: "app-companies",
    templateUrl: "./companies.component.html",
    styleUrls: ["./companies.component.css"],
    animations: [
        trigger("detailExpand", [
            state(
            "collapsed",
            style({ height: "0px", minHeight: "0", display: "none" })
            ),
            state("expanded", style({ height: "*" })),
            transition(
            "expanded <=> collapsed",
            animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})

// #=========== ---- =========== Companies Component =========== ---- ===========# //
export class CompaniesComponent implements OnInit{
    // Table variables
    allCompanies: any[];
    companies: MatTableDataSource<any>;
    displayedColumns: string[] = ["name", "senderName", "senderEmail"];
    expandedElement: any | null;

    // #=========== ---- =========== Companies Component constructor =========== ---- ===========# //
    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private authService: AuthenticationService,
        private companyService: CompanyService,
    ) {}

    // #=========== ---- =========== Companies Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authService.isLoggedInWithRedirect();
        this.companyService.getAllCompanies().subscribe((data) => {
            if (data.message === "success") {
                this.allCompanies = data.res.companies;
                this.companies = new MatTableDataSource(this.allCompanies);
                this.companies.filterPredicate = (data, filter) => {
                    const nameMatch = data.name.toLowerCase().includes(filter);
                    const senderNameMatch = data.senderName.toLowerCase().includes(filter);
                    const senderEmailMatch = data.senderEmail.toLowerCase().includes(filter);

                    return (nameMatch || senderNameMatch || senderEmailMatch);
                }
            } else {
                this.snackBar.open("Failed to fetch companies", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });   
            }
        });
    }

    // #=========== ---- =========== Search Method =========== ---- ===========# //
    search(searchValue: string) {
        this.companies.filter = searchValue.trim().toLowerCase();
    }

    // #=========== ---- =========== Handle Edit Company=========== ---- ===========# //
    handleEditCompany(companyId: string) {
        this.router.navigate(["/companies/", companyId]);
    }

    // #=========== ---- =========== Handle Create Company =========== ---- ===========# //
    handleCreateCompany() {
        this.router.navigate(["/companies/create"]);
    }
}