// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { CompanyService } from "src/app/services/company.service";

// #=========== ---- =========== Import Utils =========== ---- ===========# //
import { companyInputFields, populateFormArray, getSelectedValues } from "../company-utils";

// #=========== ---- =========== Edit Company Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-edit-company",
  templateUrl: "./edit-company.component.html",
  styleUrls: ["./edit-company.component.css"],
})

// #=========== ---- =========== Edit Company Component =========== ---- ===========# //
export class EditCompanyComponent implements OnInit {
    companyForm: FormGroup;
    company: any;

    leaveTypes: string[] = companyInputFields.leaveTypes;
    noDaysLeaveTypes: string[] = companyInputFields.noDaysLeaveTypes;
    customerTypes: string[] = companyInputFields.customerTypes;
    roles: string[] = companyInputFields.roles;
    modules: string[] = companyInputFields.modules;
    visitTypes: string[] = companyInputFields.visitTypes;
    doubleVisitTypes: string[] = companyInputFields.doubleVisitTypes;

    // #=========== ---- =========== Edit Company Component Constructor =========== ---- ===========# //
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private companyService: CompanyService,
    ) {}

    // #=========== ---- =========== Edit Company Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authenticationService.isLoggedInWithRedirect();

        this.companyForm = this.formBuilder.group({
            name: ["", Validators.required],
            senderName: ["", Validators.required],
            senderEmail: ["", [Validators.required, Validators.email]],
            leaveTypes: this.formBuilder.array([]),
            noDaysLeaveTypes: this.formBuilder.array([]),
            customerTypes: this.formBuilder.array([]),
            roles: this.formBuilder.array([]),
            modules: this.formBuilder.array([]),
            visitTypes: this.formBuilder.array([]),
            doubleVisitTypes: this.formBuilder.array([]),
        });

        const companyId = this.route.snapshot.paramMap.get("companyId");

        this.companyService.getCompany(companyId).subscribe(
            (res: any) => {
                if (res.message = "success") {
                    this.company = res.res.company;

                    this.companyForm.patchValue({
                        name: this.company.name,
                        senderName: this.company.senderName,
                        senderEmail: this.company.senderEmail,
                    });

                    this.populateFormArray("leaveTypes", this.leaveTypes, this.company.leaveTypes);
                    this.populateFormArray("noDaysLeaveTypes", this.noDaysLeaveTypes, this.company.noDaysLeaveTypes);
                    this.populateFormArray("customerTypes", this.customerTypes, this.company.customerTypes);
                    this.populateFormArray("roles", this.roles, this.company.roles);
                    this.populateFormArray("modules", this.modules, this.company.modules);
                    this.populateFormArray("visitTypes", this.visitTypes, this.company.visitTypes);
                    this.populateFormArray("doubleVisitTypes", this.doubleVisitTypes, this.company.doubleVisitTypes);
                }
            },(error) => {
                this.snackBar.open("Error fetching company data", "Close", {
                    verticalPosition: "top",
                    duration: 5000,
                });

                this.router.navigateByUrl("/companies");
            }
        );
    }

    // #=========== ---- =========== Edit Company Component Methods =========== ---- ===========# //
    
    // #=========== ---- =========== Populate Form Array =========== ---- ===========# //
    populateFormArray(formArrayName: string, allValues: string[], selectedValues: string[]): void {
        const formArray = this.companyForm.get(formArrayName) as FormArray;
        allValues.forEach(value => {
          const isChecked = selectedValues.includes(value);
          formArray.push(this.formBuilder.control(isChecked));
        });
    }

    // #=========== ---- =========== OnSubmit =========== ---- ===========# //
    onSubmit(): void {
        if (this.companyForm.invalid) {
            this.snackBar.open("Please fill in all required fields", "Close", {
                verticalPosition: "top",
                duration: 5000,
            });
            return;
        }

        let companyData = this.companyForm.value;

        companyData.leaveTypes = getSelectedValues(companyData.leaveTypes, this.leaveTypes);
        companyData.noDaysLeaveTypes = getSelectedValues(companyData.noDaysLeaveTypes, this.noDaysLeaveTypes);
        companyData.customerTypes = getSelectedValues(companyData.customerTypes, this.customerTypes);
        companyData.roles = getSelectedValues(companyData.roles, this.roles);
        companyData.modules = getSelectedValues(companyData.modules, this.modules);
        companyData.visitTypes = getSelectedValues(companyData.visitTypes, this.visitTypes);
        companyData.doubleVisitTypes = getSelectedValues(companyData.doubleVisitTypes, this.doubleVisitTypes);
        
        const companyId = this.route.snapshot.paramMap.get("companyId");
        this.companyService.updateCompany(companyData, companyId).subscribe(
            (res: any) => {
                if (res.message === "success") {
                    this.router.navigateByUrl("/companies");
                    this.snackBar.open("Company updated successfully", "Close", {
                        verticalPosition: "top",
                        duration: 5000,
                    });
                } else {
                    this.snackBar.open("Company update failed", "Close", {
                        verticalPosition: "top",
                        duration: 5000,
                    });

                    this.router.navigateByUrl("/companies");
                }
            }, (error) => {
                this.snackBar.open("Company update failed", "Close", {
                    verticalPosition: "top",
                    duration: 5000,
                });

                this.router.navigateByUrl("/companies");
            }
        );
    }

    // #=========== ---- =========== On Delete =========== ---- ===========# //
    onDelete(): void {
        const companyId = this.route.snapshot.paramMap.get("companyId");

        this.companyService.deleteCompany(companyId).subscribe(
            (res: any) => {
                if (res.message === "success") {
                    this.router.navigateByUrl("/companies");
                    this.snackBar.open("Company deleted successfully", "Close", {
                        verticalPosition: "top",
                        duration: 5000,
                    });
                } else {
                    this.snackBar.open("Company deletion failed", "Close", {
                        verticalPosition: "top",
                        duration: 5000,
                    });

                    this.router.navigateByUrl("/companies");
                }
            }, (error) => {
                this.snackBar.open("Company deletion failed", "Close", {
                    verticalPosition: "top",
                    duration: 5000,
                });

                this.router.navigateByUrl("/companies");
            }
        );
    }
}