// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Authentication Service Decorator =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class UserService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/users/`;
  // private baseUrl: String = `${environment.devUrlV2}/users/`;

  // #=========== ---- =========== Company Service constructor =========== ---- ===========# //
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Company Service Methods =========== ---- ===========# //
  assignSupervisor(user: any): any {
    if (user.supervisorIds.length > 0) {
      user.supervisor = user.supervisorIds[0];
    } else {
      user.supervisor = {
        name: "No Supervisor",
      };
    }
  }

  // #=========== ---- =========== Get Company Users - API =========== ---- ===========# //
  getCompanyUsers(): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(`${this.baseUrl}get-company-users`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Get User By Id =========== ---- ===========# //
  getUser(userId: String): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(`${this.baseUrl}${userId}`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Update User =========== ---- ===========# //
  updateUser(userData: any): Observable<any> {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();
    userData.companyId = companyId;

    return this.http.put(
      `${this.baseUrl}update`,
      { userData },
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Substitute User By Another - Migrate Customers =========== ---- ===========# //
  substituteUser(userId: string, substituteId: string): Observable<any> {
    const token = this.authService.getToken();
    return this.http.put(
      `${this.baseUrl}substitute/${userId}`,
      { substituteId },
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Create User =========== ---- ===========# //
  createUser(userData: any): Observable<any> {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();
    userData.companyId = companyId;
    return this.http.post(
      `${this.baseUrl}create`,
      { userData },
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Get Company Supervisors =========== ---- ===========# //
  getCompanySupervisors(): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(`${this.baseUrl}get-company-supervisors`, {
      headers: { authorization: "Bearer " + token },
    });
  }
}
