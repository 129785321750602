// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators'
// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from './authentication.service';
import { environment } from "../../environments/environment";

// #=========== ---- =========== TicketService Component =========== ---- ===========# //
@Injectable({
  providedIn: 'root'
})

export class TicketService
{
  // Urls info
  baseUrl: String = `${environment.baseUrlV2}/tickets`;
  uploadsUrl: String = `${environment.uploadsUrlV2}/customer_service/`;
  
  // #=========== ---- =========== TicketService Component Constructor =========== ---- ===========# //
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Refactored Methods =========== ---- ===========# //
  
  // #=========== ---- =========== Get Tickets =========== ---- ===========# //
  getTickets(): Observable<any> {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    // make a from date that is 90 days before now
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 180);
    const from = fromDate.toISOString().split("T")[0];

    // make a to date that is now
    let toDate = new Date();
    const to = toDate.toISOString().split("T")[0];

    return this.http.get(
      `${this.baseUrl}/get-company/${companyId}?from=${from}&to=${to}`,
      { headers: { authorization: "Bearer " + token } }
    ).pipe(
      map((data: any) => {
        if (data.message === "success") {
          return data.res.companyTickets;
        } else {
          return [];
        }
      })
    )
  }

  // #=========== ---- =========== Get Ticket =========== ---- ===========# //
  getTicket(ticketId: string) {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();

    return this.http.get(
      `${this.baseUrl}/get-ticket/${companyId}/${ticketId}`,
      { headers: { authorization: "Bearer " + token } }
    ).pipe(
      map((data: any) => {
        if (data.message === "success") {
          return data.res.ticket;
        } else {
          return {};
        }
      })
    )
  }

  // #=========== ---- =========== Close Ticket =========== ---- ===========# //
  changeTicketStatus(ticketId: string, status: string): Observable<any> {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();
    
    return this.http.put(
      `${this.baseUrl}/change-status/${companyId}/${ticketId}`,
      { status },
      { headers: { authorization: "Bearer " + token } }
    ).pipe(
      map((data: any) => {
        if (data.message === "success") {
          return data.res.ticket;
        } else {
          return null;
        }
      })
    )
  }

  // #=========== ---- =========== Send Message =========== ---- ===========# //
  sendMessage(ticketId: string, messageData: FormData): Observable<any> {
    // create FormData
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();
    
    return this.http.post(
      `${this.baseUrl}/send-message/${companyId}/${ticketId}`,
      messageData,
      { headers: { authorization: "Bearer " + token } }
    ).pipe(
      map((data: any) => {
        if (data.message === "success") {
          return data.res.ticket;
        } else {
          return null;
        }
      })
    )
  }

  // #=========== ---- =========== Create Ticket =========== ---- ===========# //
  createTicket(userId: string,ticketData: FormData): Observable<any> {
    const companyId = this.authService.getCompanyId();
    const token = this.authService.getToken();
    
    return this.http.post(
      `${this.baseUrl}/create/${companyId}/${userId}`,
      ticketData,
      { headers: { authorization: "Bearer " + token } }
    ).pipe(
      map((data: any) => {
        if (data.message === "success") {
          return data.res.ticket;
        } else {
          return null;
        }
      })
    )
  }
}
