// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { SpecialitiesService } from "src/app/services/specialties.service";

// #=========== ---- =========== Edit Speciality Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-edit-speciality",
  templateUrl: "./edit-specialty.component.html",
  styleUrls: ["./edit-specialty.component.css"],
})

// #=========== ---- =========== Edit Speciality Component =========== ---- ===========# //
export class EditSpecialtyComponent implements OnInit {
  specialityForm: FormGroup;
  curSpeciality: any;

  // #=========== ---- =========== Edit Speciality Component Constructor =========== ---- ===========# //
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private specialitiesService: SpecialitiesService
  ) {}

  // #=========== ---- =========== Edit Speciality Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();

    this.specialityForm = this.formBuilder.group({
      abbreviation: ["", Validators.required],
      speciality: ["", Validators.required],
    });

    const specialityId = this.route.snapshot.paramMap.get("specialityId");

    this.specialitiesService
      .getSpecialty(specialityId)
      .subscribe((data: any) => {
        if (data.message == "success") {
          this.curSpeciality = data.res.speciality;
          this.specialityForm.patchValue(this.curSpeciality);
        } else {
          this.router.navigate(["/specialties"]);
        }
      });
  }

  // #=========== ---- =========== Edit Speciality Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Update Speciality Method =========== ---- ===========# //
  updateSpeciality(): void {
    if (this.specialityForm.valid) {
      let speciality = this.specialityForm.value;
      const specialityId = this.curSpeciality._id;

      this.specialitiesService
        .editSpecialty(specialityId, speciality)
        .subscribe(
          (data: any) => {
            if (data.message == "success") {
              this.curSpeciality = speciality;
              this.specialityForm.patchValue(this.curSpeciality);

              this.snackBar.open("Speciality updated successfully", "Close", {
                verticalPosition: "top",
                duration: 2000,
              });
              this.router.navigate(["/specialities"]);
            }
          },
          (error) => {
            this.snackBar.open("Speciality update failed.", "Close", {
              verticalPosition: "top",
              duration: 2000,
            });
          }
        );
    } else {
      this.snackBar.open(
        "Please fill in all required fields, marked red",
        "Close",
        {
          verticalPosition: "top",
          duration: 2000,
        }
      );
    }
  }

  // #=========== ---- =========== Delete Speciality Method =========== ---- ===========# //
  deleteSpeciality(): void {
    const specialityId = this.curSpeciality._id;

    this.specialitiesService.deleteSpecialty(specialityId).subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.snackBar.open("Speciality deleted successfully", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });

          this.router.navigate(["/specialities"]);
        }
      },
      (error) => {
        this.snackBar.open("Speciality deletion failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    );
  }
}
