// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, defer, iif, of } from "rxjs";
import { filter, first, map, shareReplay, tap } from "rxjs/operators";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Specialities Service =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class SpecialitiesService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/specialities/`;

  // #=========== ---- =========== Specialities Service Constructor =========== ---- ===========# //
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Specialities Service Methods =========== ---- ===========# //

  // #=========== ---- =========== Get Specialties Method =========== ---- ===========# //
  public getSpecialties(): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(`${this.baseUrl}`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Get Specialty Method =========== ---- ===========# //
  public getSpecialty(id: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(`${this.baseUrl}${id}`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Edit Specialty Method =========== ---- ===========# //
  public editSpecialty(id: string, specialty: any): Observable<any> {
    const token = this.authService.getToken();

    return this.http.put(`${this.baseUrl}update/${id}`, specialty, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Create Specialty Method =========== ---- ===========# //
  public createSpecialty(specialty: any): Observable<any> {
    const token = this.authService.getToken();

    return this.http.post(`${this.baseUrl}create-one`, specialty, {
      headers: { authorization: "Bearer " + token },
    });
  }

  // #=========== ---- =========== Delete Specialty Method =========== ---- ===========# //

  public deleteSpecialty(id: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.delete(`${this.baseUrl}delete/${id}`, {
      headers: { authorization: "Bearer " + token },
    });
  }
}
