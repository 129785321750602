import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../services/authentication.service';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.css']
})

export class CreateEmailComponent implements OnInit {
  isTesting: string;
  newEmail: any = {
    name: "",
    subject: "",
    senderEmail: "",
    senderName: "",
    body: "",
    companyId: "",
    isTesting: false
  };

  senderEmailsOptions = ["noreply@menarini-uae.com", "noreply@nichepharma.com", "moataz.elshagie@nichepharma.com"]
  senderNamesOptions = ["Menarini", "Noreply-Nichepharma", "Nichepharma"]

  constructor(
    private snackbar: MatSnackBar,
    private authService: AuthenticationService,
    private emailService: EmailService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.authService.isLoggedInWithRedirect();
    const companyId = this.authService.getCompanyId();
    this.newEmail.companyId = companyId;
  }

  onCreateClick(): void {
    this.newEmail.isTesting = this.isTesting === "true" ? true : false;
    this.emailService
      .create(this.newEmail)
      .subscribe(data => {
        if (data.status === "success") {
          this.snackbar.open("Email Created!", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
          this.router.navigateByUrl("/emails");
        }
      });
  }
}
