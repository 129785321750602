import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

const leaveTypes = [
    "Annual",
    "Casual",
    "Sick",
    "Marital",
    "Mourning",
    "Maternity"
];

const noDaysLeaveTypes = [
    "NFA",
    "Replacement"
];

const customerTypes = [
    "Private Market",
    "Pharmacies",
    "Hospitals"
];

const roles = [
    "HR",
    "Marketing",
    "Promoter",
    "Sales Rep",
    "Sales Rep/Promoter",
    "Supervisor",
    "Trainer"
];

const modules = [
    "leave-management",
    "notifications",
    "dashboard",
    "plan-draft",
    "products",
    "customers",
    "id-browser",
    "adhoc",
    "intelligences",
    "samples",
    "regions",
    "promoters",
    "customer-service",
    "evaluations",
    "users",
    "email"
];

const visitTypes = [
    "Single",
    "Double",
    "Workshop",
    "Internal Meeting",
    "Conference"
];

const doubleVisitTypes = [
    "Coaching",
    "Business Follow Up",
    "KOL Double Visit",
    "Check"
];

export const companyInputFields = {
    leaveTypes,
    noDaysLeaveTypes,
    customerTypes,
    roles,
    modules,
    visitTypes,
    doubleVisitTypes
};

export function populateFormArray(formBuilder: FormBuilder, formArrayName: string, allValues: string[], selectedValues: string[], formGroup: FormGroup): void {
    const formArray = formGroup.get(formArrayName) as FormArray;
    allValues.forEach(value => {
        const isChecked = selectedValues.includes(value);
        formArray.push(formBuilder.control(isChecked));
    });
}

export function getSelectedValues(formArrayValues: boolean[], allValues: string[]): string[] {
    return allValues.filter((value, index) => formArrayValues[index]);
}