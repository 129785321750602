import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class VisitService
{

  public baseUrl: String = "http://tacitapp.herokuapp.com/api/visits/";
  baseUrlDO : String = "http://104.248.203.173:3000/api/visits/"
    baseUrlApi : String = "https://api.tacitapp.com/api/customers/"
  constructor(public http: HttpClient, public authService: AuthenticationService, public settingsService: SettingsService) { }

  public findVisits(companyId): Observable<any>
  {
    let from = this.settingsService.getFromDate();
    let to = this.settingsService.getToDate();
    if(from && to)
    {
      return this.http.get(this.baseUrlApi + "get/" + companyId + "?from=" + from.toISOString() + "&to=" + to, {headers: {"x-access-token": this.authService.getToken()}});
    }
    return this.http.get(this.baseUrlApi + "get/" + companyId, {headers: {"x-access-token": this.authService.getToken()}});
  }

  public findVisitsByUser(companyId, userId): Observable<any>
  {
    let from = this.settingsService.getFromDate();
    let to = this.settingsService.getToDate();
    if(from && to)
    {
      return this.http.get(this.baseUrlApi + "get/by-user/" + companyId + "/" + userId + "?from=" + from.toISOString() + "&to=" + to, {headers: {"x-access-token": this.authService.getToken()}});
    }
    return this.http.get(this.baseUrlApi + "get/by-user/" + companyId + "/" + userId, {headers: {"x-access-token": this.authService.getToken()}});
  }

  public findVisitsByCustomer(companyId, customerId): Observable<any>
  {
    let from = this.settingsService.getFromDate();
    let to = this.settingsService.getToDate();
    if(from && to)
    {
      return this.http.get(this.baseUrlApi + "get/by-customer/" + companyId + "/" + customerId + "?from=" + from.toISOString() + "&to=" + to, {headers: {"x-access-token": this.authService.getToken()}});
    }
    return this.http.get(this.baseUrlApi + "get/by-customer/" + companyId + "/" + customerId, {headers: {"x-access-token": this.authService.getToken()}});
  }

  public findVisitsByProduct(companyId, productId): Observable<any>
  {
    let from = this.settingsService.getFromDate();
    let to = this.settingsService.getToDate();
    if(from && to)
    {
      return this.http.get(this.baseUrlApi + "get/by-product/" + companyId + "/" + productId + "?from=" + from.toISOString() + "&to=" + to, {headers: {"x-access-token": this.authService.getToken()}});
    }
    return this.http.get(this.baseUrlApi + "get/by-product/" + companyId + "/" + productId, {headers: {"x-access-token": this.authService.getToken()}});
  }



}
