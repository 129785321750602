// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatTableDataSource } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { CallExpirationService } from "../services/call-expiration.service";
import { PrintService } from "../services/print.service";

// #=========== ---- =========== Call Expirations Component Decorator =========== ---- ===========# //
@Component({
    selector: "app-call-expirations",
    templateUrl: "./call-expirations.component.html",
})

// #=========== ---- =========== Call Expirations Component =========== ---- ===========# //
export class CallExpirationsComponent implements OnInit {
    // filter variables
    filter: any = {};
    countries: string[] = [];

    // table variables
    callExpirations: any = [];
    callExpirationsDataSource: MatTableDataSource<any>;
    displayedColumns = ["#", "country", "expirationDays"];

    // #=========== ---- =========== Call Expirations Component Constructor =========== ---- ===========# //
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private callExpirationService: CallExpirationService,
        private printService: PrintService
    ) {}

    ngOnInit(): void {
        // checking if user is logged in
        this.authenticationService.isLoggedInWithRedirect();

        this.callExpirationService.getCompanyCallExpirations().subscribe((data: any) => {
            if (data.message === "success") {
                this.callExpirations = data.res.callExpirations;

                this.callExpirations.forEach((callExpiration: any) => {
                    this.countries.push(callExpiration.countryId.name);
                })

                this.callExpirationsDataSource = new MatTableDataSource(this.callExpirations);

                // For the Search bar functionality
                this.callExpirationsDataSource.filterPredicate = (data: any, filter: string) => {
                    const isCountryMatch = data.countryId.name.toLowerCase().includes(filter);
                    const isExpirationDaysMatch = data.expirationDays.toString().includes(filter);

                    return isCountryMatch || isExpirationDaysMatch;
                };
            }
        });        
    }

    // #=========== ---- =========== Call Expirations Component Methods =========== ---- ===========# //

    // #=========== ---- =========== Apply Filter Method =========== ---- ===========# //
    applyFilter() {
        let filteringData = this.callExpirations;

        for (let attr in this.filter) {
            if (this.filter[attr] !== undefined) {
                if(attr === "country") {
                    filteringData = filteringData.filter((callExpiration: any) => {
                        return callExpiration.countryId.name === this.filter[attr];
                    });
                }
            }
        }

        this.callExpirationsDataSource = new MatTableDataSource(filteringData);
    }

    // #=========== ---- =========== Search Method =========== ---- ===========# //
    search(searchValue: String) {
        this.callExpirationsDataSource.filter = searchValue.trim().toLowerCase();
    }

    // #=========== ---- =========== Print Method =========== ---- ===========# //
    print(): void {
        const companyName = this.authenticationService.getCompanyName();

        let orientation = "portrait";
        let reportCols = [
            { title: "Country", dataKey: "country" },
            { title: "Expiration Days", dataKey: "expirationDays" },
        ];
        let reportHeader = `${companyName} Call Expirations`;
        let filename = `${companyName}-call-expirations.pdf`;

        const callExpirationsToPrint = this.callExpirationsDataSource.filteredData.map((callExpiration: any) => {
            return {
                country: callExpiration.countryId.name,
                expirationDays: callExpiration.expirationDays
            };
        });

        this.printService.printTable(
            orientation,
            reportCols,
            callExpirationsToPrint,
            reportHeader,
            filename
        );
    }

    // #=========== ---- =========== Handle Call Expiration Click Method =========== ---- ===========# //
    handleCallExpirationClick(callExpiration: any): void {
        this.router.navigateByUrl(`/call-expirations/${callExpiration._id}`);
    }

    // #=========== ---- =========== Handle Create Call Expiration Click Method =========== ---- ===========# //
    handleCreateCallExpirationClick(): void {
        this.router.navigateByUrl("/call-expirations/create");
    }
}
