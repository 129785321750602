// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder } from "@angular/forms";
import * as moment from "moment";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { UserService } from "../services/user.service";
import { LineService } from "../services/line.service";
import { CountryService } from "../services/country.service";
import { PrintService } from "../services/print.service";
import { FormatService } from "../services/format.service";
import { ReportsService } from "../services/reports.service";

// #=========== ---- =========== User Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})

// #=========== ---- =========== User Component =========== ---- ===========# //
export class UsersComponent implements OnInit {
  // filter variables
  filter: any = {};
  countries: string[] = [];
  provinces: string[] = [];
  cities: string[] = [];
  supervisors: string[] = [];
  lines: string[] = [];
  roles: string[] = [];

  locations: any;

  // table variables
  users: any = [];
  usersDataSource: MatTableDataSource<any>;
  displayedColumns = [
    "#",
    "name",
    "username",
    "role",
    "country",
    "province",
    "city",
    "supervisor",
    "lines",
    "isActive",
  ];

  // #=========== ---- =========== User Component Constructor =========== ---- ===========# //
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private countryService: CountryService,
    private lineService: LineService,
    private router: Router,
    private printService: PrintService,
    private formatService: FormatService,
    private formBuilder: FormBuilder,
    private reportsService: ReportsService
  ) {}

  // #=========== ---- =========== User Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    // checking if user is logged in
    this.authenticationService.isLoggedInWithRedirect();

    this.userService.getCompanyUsers().subscribe((data: any) => {
      if (data.message == "success") {
        // handling the users array operations
        this.users = data.res.companyUsers;
        this.prepareUserData(this.users);

        // setting the table data source and its filter predicate (function)
        this.usersDataSource = new MatTableDataSource(this.users);
      }
    });
  }

  // #=========== ---- =========== User Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Apply Filter Method =========== ---- ===========# //

  applyFilter() {
    let filteringData = this.users;

    for (let attr in this.filter) {
      if (this.filter[attr] != undefined) {
        if (attr == "country") {
          filteringData = filteringData.filter(
            (user) => user.country == this.filter[attr]
          );
        } else if (attr == "province") {
          filteringData = filteringData.filter(
            (user) => user.province == this.filter[attr]
          );
        } else if (attr == "city") {
          filteringData = filteringData.filter(
            (user) => user.city == this.filter[attr]
          );
        } else if (attr == "supervisor") {
          filteringData = filteringData.filter(
            (user) => user.supervisor.name == this.filter[attr]
          );
        } else if (attr == "line") {
          filteringData = filteringData.filter((user) =>
            user.linesNames.includes(this.filter[attr])
          );
        } else if (attr == "role") {
          filteringData = filteringData.filter(
            (user) => user.role == this.filter[attr]
          );
        } else if (attr == "isActive") {
          filteringData = filteringData.filter((user) => {
            if (this.filter[attr] == "All") {
              return true;
            } else if (this.filter[attr] == "Active") {
              return user.isActive;
            } else {
              return !user.isActive;
            }
          });
        }
      }
    }

    this.usersDataSource = new MatTableDataSource(filteringData);
  }

  // #=========== ---- =========== Search Method =========== ---- ===========# //
  search(filterValue: string) {
    this.usersDataSource.filter = filterValue.trim().toLowerCase();
  }

  // #=========== ---- =========== Prepare User Data Method =========== ---- ===========# //
  prepareUserData(users: any): void {
    users.forEach((user: any) => {
      // Lines
      if (user.lines) {
        user.linesData = user.lines;
        user.lines = user.linesData.map((line: any) => line.name).join(", ");
        user.linesNames = user.linesData.map((line: any) => line.name);
      } else {
        user.lines = "No Lines Assigned";
        user.linesNames = [];
      }

      // Supervisor
      if (user.supervisorIds.length > 0) {
        user.supervisor = user.supervisorIds[0];
      } else {
        user.supervisor = "No Supervisor Assigned";
      }

      this.fillFilterOptions(user);
    });
  }

  // #=========== ---- =========== Fill Filter Options Method =========== ---- ===========# //
  fillFilterOptions(user) {
    if (!this.countries.includes(user.country)) {
      this.countries.push(user.country);
    }
    if (!this.provinces.includes(user.province)) {
      this.provinces.push(user.province);
    }

    if (!this.cities.includes(user.city)) {
      this.cities.push(user.city);
    }

    if (!this.roles.includes(user.role)) {
      this.roles.push(user.role);
    }

    if (!this.supervisors.includes(user.supervisor.name)) {
      this.supervisors.push(user.supervisor.name);
    }
    user.linesData.forEach((line) => {
      if (!this.lines.includes(line.name)) {
        this.lines.push(line.name);
      }
    });
  }

  // #=========== ---- =========== Print Method =========== ---- ===========# //

  print(): void {
    const reportCols = [
      { title: "#", dataKey: "index" },
      { title: "Name", dataKey: "name" },
      { title: "Username", dataKey: "usernameWithCompany" },
      { title: "Password", dataKey: "companyName" },
      { title: "Role", dataKey: "role" },
      { title: "Country", dataKey: "country" },
      { title: "Province", dataKey: "province" },
      { title: "Supervisor", dataKey: "supervisorName" },
      { title: "Lines", dataKey: "lines" },
      { title: "Active", dataKey: "isActive" },
    ];

    // processing users to print
    let filtered = this.usersDataSource.filteredData;
    let i = 1;
    filtered = filtered.map((user: any, index) => {
      user.index = i;
      i += 1;
      user.supervisorName = user.supervisor.name;
      user.companyName = this.authenticationService.getCompanyName();
      user.usernameWithCompany =
        user.username + "@" + this.authenticationService.getCompanyName();
      return user;
    });

    this.printService.printTable(
      "landscape",
      reportCols,
      filtered,
      "Users Details - " +
        this.formatService.formatDateTimeToFullDateTime(null),
      this.authenticationService.getCompanyName() + "-Users-Details"
    );
  }

  // #=========== ---- =========== Export Method =========== ---- ===========# //
  export(): void {
    let companyName = this.authenticationService.getCompanyName();
    let report = [
      [
        "#",
        "Name",
        "Username",
        "Role",
        "Country",
        "Province",
        "Supervisor",
        "Lines",
        "Active",
      ],
    ];

    let filtered = this.usersDataSource.filteredData;
    filtered.forEach((user: any, index) => {
      let row = [
        index + 1,
        user.name,
        user.username + +"@" + companyName,
        user.role,
        user.country,
        user.province,
        user.supervisor.name,
        user.lines,
        user.isActive,
      ];
      report.push(row);
    });

    let filename =
      companyName +
      " Users - Created At " +
      moment(Date.now()).format("dddd, MMMM Do YYYY h:mm:ss a");

    this.reportsService.outputFileWithOneWorksheet(
      report,
      filename,
      companyName + " Users"
    );
  }

  // #=========== ---- =========== Handle User Click Method =========== ---- ===========# //
  handleUserClick(user: any): void {
    this.router.navigateByUrl("/users/" + user._id);
  }

  // #=========== ---- =========== Handle Create User Click Method =========== ---- ===========# //
  handleCreateUserClick(): void {
    this.router.navigateByUrl("/users/create");
  }
}
