import { Component, OnInit } from "@angular/core";
import { MatTableDataSource, MatSnackBar } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";

import * as juice from "juice"; //For inline CSS
import * as XLSX from "xlsx";

import { CompanyService } from "../../services/company.service";
import { EmailService } from "../../services/email.service";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: "app-email",
  templateUrl: "./send-email.component.html",
  styleUrls: ["./send-email.component.css"],
})
export class SendEmailComponent implements OnInit {
  private fromName: string = "Menarini";
  private fromEmail: string = "noreply@menarini-uae.com";

  displayedColumns = [
    "name",
    "specialty",
    "type",
    "class",
    "country",
    "province",
    "city",
    "email",
  ];
  dataSource: MatTableDataSource<any>;

  filter: any = {};
  filteredCustomers: any = [];

  emailsFromSheet = [];
  fromSheetOnly: boolean = false;
  isAdaptable: boolean = false;

  emailId: String = "";
  companyId: string = "";
  customers: any = [];
  email: any;

  specialties: any = [];
  classes: any = [];
  cities: any = [];
  provinces: any = [];
  countries: any = [];

  specialtiesSet = new Set();
  classesSet = new Set();
  countriesSet = new Set();
  provincesSet = new Set();
  citiesSet = new Set();

  loading = false;
  successMessage: string | null = null;
  errorMessage: string = "";
  enableFollowUpSending: boolean = false;
  followUpDays: number;
  constructor(
    public companyService: CompanyService,
    public authService: AuthenticationService,
    public router: Router,
    public emailService: EmailService,
    public activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.authService.isLoggedInWithRedirect();
    this.companyId = this.authService.getCompanyId();
    this.emailId = this.activatedRoute.snapshot.paramMap.get("emailId");

    this.emailService.find(this.emailId).subscribe((data) => {
      // console.log(data)
      if (data.status == "success") {
        this.email = data.response;
        // console.log(data.response.body)
      }
    });

    this.companyService.findCustomers(this.companyId).subscribe((data) => {
      if (data.status == "success") {
        this.customers = data.response.customersDetails;
        this.filteredCustomers = this.customers;
        this.dataSource = new MatTableDataSource<any>(this.customers);

        this.customers.forEach((customer) => {
          this.specialtiesSet.add(customer.speciality);
          this.classesSet.add(customer.class);
          this.countriesSet.add(customer.location.country);
          this.provincesSet.add(customer.location.province);
          this.citiesSet.add(customer.location.city);
        });

        this.specialties = Array.from(this.specialtiesSet);
        this.classes = Array.from(this.classesSet);
        this.countries = Array.from(this.countriesSet);
        this.provinces = Array.from(this.provincesSet);
        this.cities = Array.from(this.citiesSet);
      } else {
        console.log(data);
      }
    });
  }

  openEmailEditor(): void {
    this.router.navigateByUrl("/email/editor");
  }

  applyFilter(): void {
    this.filteredCustomers = this.customers;

    for (let attr in this.filter) {
      if (this.filter[attr] != undefined) {
        if (attr == "specialty") {
          this.filteredCustomers = this.filteredCustomers.filter(
            (customer) => customer.speciality == this.filter[attr]
          );
        } else if (attr == "type") {
          this.filteredCustomers = this.filteredCustomers.filter(
            (customer) => customer.type == this.filter[attr]
          );
        } else if (attr == "class") {
          this.filteredCustomers = this.filteredCustomers.filter(
            (customer) => customer.class == this.filter[attr]
          );
        } else if (attr == "country") {
          this.filteredCustomers = this.filteredCustomers.filter(
            (customer) => customer.location.country == this.filter[attr]
          );
        } else if (attr == "province") {
          this.filteredCustomers = this.filteredCustomers.filter(
            (customer) => customer.location.province == this.filter[attr]
          );
        } else if (attr == "city") {
          this.filteredCustomers = this.filteredCustomers.filter(
            (customer) => customer.location.city == this.filter[attr]
          );
        }
      }
    }

    this.dataSource = new MatTableDataSource<any>(this.filteredCustomers);
  }

  sendEmail(): void {
    let recipientsList = [];
    this.loading = true;
    const options = {
      enableFollowUpSending: this.enableFollowUpSending,
      followUpDays: this.followUpDays,
      fromName: this.fromName,
      fromEmail: this.fromEmail,
    };
    if (this.fromSheetOnly) {
      // console.log('emailsFromSheet To: ' + JSON.stringify(this.emailsFromSheet));

      for (let i = 0; i < this.emailsFromSheet.length; i++) {
        let element = this.emailsFromSheet[i];
        // console.log('Sending To: ' + element.Email );
        let recipient = {
          name: element.Name,
          firstName: element.FirstName,
          lastName: element.LastName,
          email: element.Email,
          class: element.Class,
          specialty: element.Specialty,
          location: {
            country: element.Country,
            province: element.Province,
            city: element.City,
          },
          center: element.Center,
          sentTimestamp: Date.now(),
        };
        recipientsList.push(recipient);
      }

      this.emailService
        .sendEmails(
          this.emailId,
          this.email.body,
          this.email.subject,
          recipientsList,
          options
        )
        .subscribe((data) => {
          // console.log(data)
          if (data.status == "success") {
            // console.log(data);
          }
          this.loading = false;
          this.showSuccessMessage("Emails sent successfully!");
        });
    }
  }

  private showSuccessMessage(message: string): void {
    const snackBarRef = this.snackBar.open(message, "Close", {
      duration: 5000, // Duration in milliseconds
      horizontalPosition: "center",
      verticalPosition: "top",
      panelClass: "success-message", // Add your custom class for styling
    });

    snackBarRef.afterDismissed().subscribe(() => {
      window.location.reload();
    });
  }

  inlineCSS(htmlString): String {
    return juice(htmlString);
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error("Cannot use multiple files");
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      try {
        const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
        // @ts-ignore
        let currentSheet = XLSX.utils.sheet_to_json(ws, { defval: "" });
        // console.log(currentSheet);
        // Validate column names
        const columnNames: string[] = Object.keys(currentSheet[0]);
        const expectedColumnNames: string[] = [
          "#",
          "Name",
          "FirstName",
          "Email",
          "Specialty",
          "Class",
          "Country",
          "Province",
          "City",
          "Center",
        ];
        const isValidColumns: boolean = expectedColumnNames.every((column) =>
          columnNames.includes(column)
        );
        if (!isValidColumns) {
          this.snackBar.open(
            "Columns in the file do not match expected column names: ['Name', 'FirstName', 'Email', 'Specialty', 'Class', 'Country', 'Province', 'City', 'Center'].",
            "Close",
            {
              duration: 5000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: ["error-snackbar"],
            }
          );
          evt.target.value = "";
          return;
        }
        this.emailsFromSheet = currentSheet;
      } catch (err) {
        console.log(err);
      }
    };

    reader.readAsBinaryString(target.files[0]);
  }
}
