import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { Chart } from "chart.js";

@Injectable({
  providedIn: "root",
})
export class ChartService {
  /*
        legend: {
            display: true,
            position: "bottom",
            labels: {
                fontColor: "#333",
                fontSize: 16
            }
        },
    */

  constructor(
    public http: HttpClient,
    public authService: AuthenticationService
  ) {}

  drawBarChart(
    chartInstance,
    elementId,
    x_axis_labels,
    values,
    chartTitle
  ): void {
    let canvas = document.getElementById(elementId) as any;
    let ctx = canvas.getContext("2d");
    if (chartInstance) {
      chartInstance.destroy();
    }

    let randomColors = [];

    for (let i = 0; i < x_axis_labels.length; i++) {
      randomColors.push(this.getRandomColor());
    }

    chartInstance = new Chart(ctx, {
      type: "bar",
      data: {
        labels: x_axis_labels.map(
          (label, index) => `${label}: ${values[index]}`
        ),
        datasets: [
          {
            data: values,
            borderColor: randomColors,
            fillColor: randomColors,
            backgroundColor: randomColors,
            fill: true,
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: chartTitle,
          fontSize: 20,
        },
        responsive: true,
        ticks: {
          autoSkip: false,
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  drawClicksBarChart(
    chartInstance,
    elementId,
    x_axis_labels,
    valuesTotal,
    valuesUnique,
    chartTitle
  ): void {
    let canvas = document.getElementById(elementId) as any;
    let ctx = canvas.getContext("2d");
    if (chartInstance) {
      chartInstance.destroy();
    }
    let randomColor = this.getRandomColor();
    let randomColorTwo = this.getRandomColor();
    const data = {
      labels: x_axis_labels,
      datasets: [
        {
          label: "Total Clicks",
          data: valuesTotal,
          borderColor: randomColor,
          fillColor: randomColor,
          backgroundColor: randomColor,
          fill: true,
        },
        {
          label: "Unique Clicks",
          data: valuesUnique,
          borderColor: randomColorTwo,
          fillColor: randomColorTwo,
          backgroundColor: randomColorTwo,
          fill: true,
        },
      ],
    };
    chartInstance = new Chart(ctx, {
      type: "bar",
      data,
      options: {
        title: {
          display: true,
          text: chartTitle,
          fontSize: 20,
        },
        responsive: true,
        ticks: {
          autoSkip: false,
        },
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  drawBarChartWithMultipleDatasets(
    chartInstance,
    elementId,
    x_axis_labels,
    datasetLabels,
    values,
    chartTitle
  ): void {
    let canvas = document.getElementById(elementId) as any;
    let ctx = canvas.getContext("2d");
    if (chartInstance) {
      chartInstance.destroy();
    }

    let datasets = [];
    for (let i = 0; i < datasetLabels.length; i++) {
      let randomColor = this.getRandomColor();
      let dataset = {
        label: datasetLabels[i],
        data: values[i],
        borderColor: randomColor,
        fillColor: randomColor,
        backgroundColor: randomColor,
        fill: true,
      };
      datasets.push(dataset);
    }

    chartInstance = new Chart(ctx, {
      type: "bar",
      data: {
        labels: x_axis_labels,
        datasets: datasets,
      },
      options: {
        title: {
          display: true,
          text: chartTitle,
          fontSize: 20,
        },
        responsive: true,
        ticks: {
          autoSkip: false,
        },
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              display: true,
            },
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  getRandomColor(): String {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
