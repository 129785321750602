import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService 
{
  public key: string = "settings";
  constructor() { }

  public saveSettings(settings): void
  {
    window.localStorage.setItem(this.key, JSON.stringify(settings));
  }

  public getFromDate(): Date
  {
    if(!JSON.parse(window.localStorage.getItem('settings')))
    {
      return null;
    }
    return new Date(JSON.parse(window.localStorage.getItem('settings')).from);
  }
  
  public getToDate(): Date
  {
    if(!JSON.parse(window.localStorage.getItem('settings')))
    {
      return null;
    }
    return new Date(JSON.parse(window.localStorage.getItem('settings')).to);
  }




}
