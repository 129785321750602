// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Authentication Service Decorator =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class CompanyService {
  private baseUrl: String = `${environment.devUrlV2}/companies`;
  // private baseUrl: String = `${environment.baseUrlV2}api-v2/companies`;
  baseUrlApiUnRef: String = "https://api.tacitapp.com/api/companies/";

  // #=========== ---- =========== Company Service constructor =========== ---- ===========# //
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Company Service Methods =========== ---- ===========# //
  
  // #=========== ---- =========== Get Company =========== ---- ===========# //
  getCompany(companyId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(
      `${this.baseUrl}/get-one/${companyId}`,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Get All Companies =========== ---- ===========# //
  getAllCompanies(): Observable<any> {
    const token = this.authService.getToken();

    return this.http.get(
      `${this.baseUrl}/get-all`,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Update Company =========== ---- ===========# //
  updateCompany(companyData: any, companyId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.put(
      `${this.baseUrl}/update/${companyId}`,
      companyData,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Delete Company =========== ---- ===========# //
  deleteCompany(companyId: string): Observable<any> {
    const token = this.authService.getToken();

    return this.http.put(
      `${this.baseUrl}/delete/${companyId}`,
      {},
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== Create Company =========== ---- ===========# //
  createCompany(companyData: any): Observable<any> {
    const token = this.authService.getToken();

    return this.http.post(
      `${this.baseUrl}/create`,
      companyData,
      { headers: { authorization: "Bearer " + token } }
    );
  }

  // #=========== ---- =========== UnRefactored Get Company Customers =========== ---- ===========# //
  findCustomers(companyId): Observable<any> {
    return this.http.get(this.baseUrlApiUnRef + "get/customers/" + companyId, {
      headers: { "x-access-token": this.authService.getToken() },
    });
  }
}
