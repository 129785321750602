// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//
export const environment = {
  production: false,

  devUrlV2: "http://localhost:3011/api-v2",
  ipUrlV2: "http://104.248.203.173:3011/",
  baseUrlV2: "https://rest-api-v2.tacitapp.com/",

  baseUrlV2Emails: "https://rest-api-v2.tacitapp.com/api/emails/",
  uploadsUrlV2: "https://rest-api-v2.tacitapp.com/uploads",

  devUrlV1: "http://localhost:3000/",
  ipUrlV1: "http://104.248.203.173:3000/",
  baseUrlV1: "http://localhost:3000/api",

  baseUrl: "https://tacitapp.herokuapp.com/",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.