// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { SlicePipe } from "@angular/common";
import { FormGroup, FormBuilder } from "@angular/forms";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatTableDataSource } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { TestimonialService } from "../services/testimonial.service";

// #=========== ---- =========== Testimonials Component Decorator =========== ---- ===========# //

@Component({
  selector: "app-testimonials",
  templateUrl: "./testimonials.component.html",
  styleUrls: ["./testimonials.component.css"],
  providers: [SlicePipe],
})

// #=========== ---- =========== Testimonials Component =========== ---- ===========# //
export class TestimonialsComponent implements OnInit {
  // filter variables
  companies: any[] = [];

  // table variables
  filter: any = {};
  testimonials: any = [];
  testimonialsDataSource: MatTableDataSource<any>;
  displayedColumns = ["#", "name", "company", "feedback", "video", "date"];
  showFullFeedback = false;
  videoBaseUrl =
    "https://nichepharma-testimonial.fra1.digitaloceanspaces.com/testimonial/";

  // #=========== ---- =========== Testimonials Component Constructor =========== ---- ===========# //
  constructor(
    private authenticationService: AuthenticationService,
    private testimonialService: TestimonialService,
    private formBuilder: FormBuilder
  ) {}

  // #=========== ---- =========== Testimonials Component ngOnInit =========== ---- ===========# //

  ngOnInit(): void {
    // checking if user is logged in
    this.authenticationService.isLoggedInWithRedirect();

    // getting the testimonials
    this.testimonialService.getTestimonials().subscribe((data: any) => {
      if (data.message == "success") {
        data.res.testimonials.forEach((testimonial) => {
          this.testimonials.push({
            name: testimonial.userId.name,
            company: testimonial.companyId.name,
            feedback: testimonial.feedback,
            video: testimonial.video,
            date: testimonial.createdAt.slice(0, 10),
          });

          if (!this.companies.includes(testimonial.companyId.name)) {
            this.companies.push(testimonial.companyId.name);
          }
        });
        this.testimonialsDataSource = new MatTableDataSource(this.testimonials);
      }
    });
  }

  // #=========== ---- =========== Testimonials Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Apply Filter Method =========== ---- ===========# //
  applyFilter(): void {
    let filteringData = this.testimonials;

    for (let attr in this.filter) {
      if (this.filter[attr] != undefined) {
        if (attr == "company") {
          filteringData = filteringData.filter(
            (testimonial) => testimonial.company == this.filter[attr]
          );
        }
      }
    }

    this.testimonialsDataSource = new MatTableDataSource(filteringData);
  }
}
