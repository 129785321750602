// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Libraries =========== ---- ===========# //
import * as moment from 'moment';

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { TicketService } from "../services/ticket.service";
import { AuthenticationService } from '../services/authentication.service';

// #=========== ---- =========== Customer-Service Component Decorator =========== ---- ===========# //
@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.css']
})

// #=========== ---- =========== Customer-Service Component =========== ---- ===========# //
export class CustomerServiceComponent implements OnInit
{
  user: any;

  // Ticket Form
  message: string = "";
  filesToUpload: Array<File> = [];

  // Tickets Data
  tickets = [];
  openTickets = [];
  closedTickets = [];

  // #=========== ---- =========== Customer-Service Component Constructor =========== ---- ===========# //
  constructor(
    private snackBar: MatSnackBar,
    private ticketService: TicketService,
    private authService: AuthenticationService,
  ) {}

  // #=========== ---- =========== Customer-Service Component ngOnInit =========== ---- ===========# //
  ngOnInit() {
    this.authService.isLoggedInWithRedirect();
    this.user = this.authService.getUser();

    this.ticketService.getTickets()
      .subscribe((tickets: any) => {
        if (tickets.length > 0) {
          this.tickets = tickets;
          this.openTickets = this.tickets.filter(ticket => ticket.status === "OPEN");
          this.closedTickets = this.tickets.filter(ticket => ticket.status === "CLOSED");
        } else {
          this.snackBar.open("No tickets found", "Close", {
            verticalPosition: 'top',
            duration: 2000 
          });
        }
      });
  }

  // #=========== ---- =========== Customer-Service Component Methods =========== ---- ===========# //
  formatDateTime(dateTime: any) {
    return moment(dateTime).format('DD/MM/YYYY');
  }

}