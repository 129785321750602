import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';

import * as moment from 'moment';

import { AuthenticationService } from '../services/authentication.service';
import { EmailService } from '../services/email.service';
import { PrintService } from '../services/print.service';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})

export class EmailsComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns = [
    'name',
    'subject',
    'sentToCount',
    'openedCount',
    'uniqueClicks',
    'clicksCount',
    'createdAt',
    'sentAt',
    "status",
    'send',
    'report',
    'edit'
  ]

  emailInfoHashTable = []

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private emailService: EmailService,
    private printService: PrintService,
  ) {}

  ngOnInit() {
    this.authService.isLoggedInWithRedirect();
    const companyId = this.authService.getCompanyId();

    // Fetch the emails sent by the account
    this.emailService
      .findByCompany(companyId)
      .subscribe(data => {
        // console.log(data)
        if (data.status === "success") {
          data.response.forEach(email => {
            this.emailInfoHashTable[email._id] = email
            this.emailInfoHashTable[email._id].clicks = email.totalNumberOfClicks;
            this.emailInfoHashTable[email._id].uniqueClicks = email.totalNumberOfUniqueClicks;
          })

          let emailsFinal = [];
          for (let email in this.emailInfoHashTable) {
            emailsFinal.push(this.emailInfoHashTable[email])
          }
          // console.log(emailsFinal)

          this.dataSource = new MatTableDataSource<any>(data.response);
          this.dataSource.data.map(email => {
            email.createdAt = moment(email.createdAt).format('DD-MM-YYYY, h:mm:ss a')
            if (email.sentAt) {
              email.sentAt = moment(email.sentAt).format('DD-MM-YYYY, h:mm:ss a');
            } else {
              // Handle the case where email.sentAt is undefined or null
              email.sentAt = "Not available";
            }
            return email;
          })
        }
      });
  }

  goToSendPage(email): void {
    this.router.navigateByUrl("/emails/send/" + email._id);
  }

  goToReportage(email): void {
    this.router.navigateByUrl("/emails/report/" + email._id);
  }

  goToCreatePage(): void {
    this.router.navigateByUrl("/emails/create");
  }

  goToEditPage(email): void {
    this.router.navigateByUrl("/emails/edit-email/" + email._id);
  }
  printEmailReports() {
    let cols = [
      {
        title: 'Name',
        dataKey: 'name'
      },
      {
        title: "Subject",
        dataKey: "subject"
      },
      {
        title: "Total Number of Sends",
        dataKey: "totalNumberOfSends"
      },
      {
        title: "Total Number of Opens",
        dataKey: "totalNumberOfOpens"
      },
      {
        title: "Total Number of Unique Opens",
        dataKey: "totalNumberOfUniqueOpens"
      },
      {
        title: "Created At",
        dataKey: "createdAt"
      },
      {
        title: "Sent At",
        dataKey: "sentAt"
      }
    ]

    let rows = this.dataSource.data.map(email => {
      email.totalNumberOfSends = email.sentTo ? email.sentTo.length : 0;
      email.totalNumberOfOpens = email.totalNumberOfOpens ? email.totalNumberOfOpens : 0;
      email.totalNumberOfUniqueOpens = email.sentTo ? email.sentTo.filter(e => e.opened == true).length : 0;
      return email;
    });

    this.printService.printTable(
      'landscape',
      cols,
      rows,
      "Emails Report - " + moment().format('dddd, MMMM Do YYYY, h:mm:ss a'),
      "Emails-Report"
    );
  }
}
