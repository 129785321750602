// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  MatSnackBar,
  MatTableDataSource,
} from "@angular/material";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { LeaveManagementTemplateService } from "../services/leave-management-template.service";

// #=========== ---- =========== Leave Management Templates Component Decorator =========== ---- ===========# //
@Component({
    selector: "app-leave-management-template",
    templateUrl: "./leave-management-template.component.html",
    styleUrls: ["./leave-management-template.component.css"],
    animations: [
        trigger("detailExpand", [
            state(
            "collapsed",
            style({ height: "0px", minHeight: "0", display: "none" })
            ),
            state("expanded", style({ height: "*" })),
            transition(
            "expanded <=> collapsed",
            animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})

// #=========== ---- =========== Leave Management Templates Component =========== ---- ===========# //
export class LeaveManagementTemplatesComponent implements OnInit {
    // Filter variables
    filter: any = {};
    countries: Set<string> = new Set();
    categories: Set<string> = new Set();

    // Table variables
    allTemplates: any[];
    leaveManagementTemplates: MatTableDataSource<any>;
    displayedColumns: string[] = ["country", "category"];
    expandedElement: any | null;

    // #=========== ---- =========== Leave Management Templates Component constructor =========== ---- ===========# //
    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private authService: AuthenticationService,
        private leaveManagementTemplateService: LeaveManagementTemplateService,
    ) {}

    // #=========== ---- =========== Leave Management Templates Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authService.isLoggedInWithRedirect();

        this.leaveManagementTemplateService.getCompanyLeaveManagementTemplates().subscribe(
            (data) => {
                if (data.message === "success") {
                    this.allTemplates = data.res.leaveManagementTemplates;

                    this.allTemplates.forEach((leaveManagementTemplate: any) => {
                        this.countries.add(leaveManagementTemplate.countryId.name);
                        this.categories.add(leaveManagementTemplate.category);
                    });
                    
                    this.leaveManagementTemplates = new MatTableDataSource(this.allTemplates);
                    this.leaveManagementTemplates.filterPredicate = (data: any, filter: string) => {
                        const isCountryMatch = data.countryId.name.toLowerCase().includes(filter);
                        const isCategoryMatch = data.category.toLowerCase().includes(filter);
                        
                        return isCountryMatch || isCategoryMatch;
                    }
                }
            },
            (error) => {
                this.snackBar.open("Error fetching leave management templates", "Close", {
                    verticalPosition: "top",
                    duration: 3000,
                });
            }
        );
    }

    // #=========== ---- =========== Apply Filter Method =========== ---- ===========# //
    applyFilter() {
        let filteringData = this.allTemplates;

        for (let attr in this.filter) {
            if (this.filter[attr] !== undefined) {
                if (attr === "country") {
                    filteringData = filteringData.filter((template: any) => {
                        return template.countryId.name === this.filter[attr];
                    });
                } else if (attr === "category") {
                    filteringData = filteringData.filter((template: any) => {
                        return template.category === this.filter[attr];
                    });
                }
            }
        }

        this.leaveManagementTemplates = new MatTableDataSource(filteringData);
    }

    // #=========== ---- =========== Search Method =========== ---- ===========# //
    search(searchValue: string) {
        this.leaveManagementTemplates.filter = searchValue.trim().toLowerCase();
    }

    // #=========== ---- =========== Handle Edit Leave Management Template=========== ---- ===========# //
    handleEditLeaveManagementTemplate(template: any) {
        this.router.navigateByUrl(`/leave-management-templates/${template._id}`);
    }

    // #=========== ---- =========== Handle Create Leave Management Template=========== ---- ===========# //
    handleCreateLeaveManagementTemplate() {
        this.router.navigateByUrl("/leave-management-templates/create");
    }
}