// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, tap } from "rxjs/operators";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Product Service =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class ProductService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/products`;
  // private baseUrl: String = `${environment.devUrlV2}/products`;
  baseUploadsUrl: String = environment.uploadsUrlV2;

  // #=========== ---- =========== Product Service Constructor =========== ---- ===========# //

  constructor(
    public http: HttpClient,
    public authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Product Service Methods =========== ---- ===========# //

  createProduct(productData: FormData, type: string) {
    const token = this.authService.getToken();

    return this.http
      .post(`${this.baseUrl}/${type}/create/`, productData, {
        headers: { authorization: "Bearer " + token },
      })
  }

  getCompanyProducts() {
    const token = this.authService.getToken();
    return this.http.get(`${this.baseUrl}/get-company-products`, {
      headers: { authorization: "Bearer " + token },
    });
  }

  getProduct(id: string) {
    const token = this.authService.getToken();

    return this.http
      .get(`${this.baseUrl}/get-one/${id}`, {
        headers: { authorization: "Bearer " + token },
      });
  }

  updateProduct(productId: string, productType: string, productData: any) {
    const token = this.authService.getToken();

    return this.http
      .put(
        `${this.baseUrl}/${productType}/update/${productId}`,
        productData,
        { headers: { authorization: "Bearer " + token } }
      )
  }

  addVersion(productId: any, productData: any) {
    const token = this.authService.getToken();

    return this.http
      .post(
        `${this.baseUrl}/add-version/${productId}`,
        productData,
        { headers: { authorization: "Bearer " + token } }
      )
  }

  editVersion(productId: any, productData: any) {
    const token = this.authService.getToken();

    return this.http
      .put(
        `${this.baseUrl}/edit-version/${productId}`,
        productData,
        { headers: { authorization: "Bearer " + token } }
      )
  }

  deleteProduct(id: string) {
    const token = this.authService.getToken();

    return this.http
      .delete(`${this.baseUrl}/delete/${id}`, {
        headers: { authorization: "Bearer " + token },
      })
  }

  createSalesCatalogue(productsData: any) {
    const token = this.authService.getToken();

    return this.http
      .post(
        `${this.baseUrl}/sales/create-bulk`,
        productsData,
        { headers: { authorization: "Bearer " + token } }
      );
  }
}