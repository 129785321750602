// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { UserPolicyService } from "src/app/services/user-policy.service";

// #=========== ---- =========== Edit User Policy Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-edit-user-policy",
  templateUrl: "./edit-user-policy.component.html",
  styleUrls: ["./edit-user-policy.component.css"],
})

// #=========== ---- =========== Edit User Policy Component =========== ---- ===========# //
export class EditUserPolicyComponent implements OnInit {
    userPolicyForm: FormGroup;
    userPolicy: any;

    roles: string[];

    modules: string[];

    // #=========== ---- =========== Edit User Policy Component Constructor =========== ---- ===========# //
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private userPolicyService: UserPolicyService,
    ) {}

    // #=========== ---- =========== Edit User Policy Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authenticationService.isLoggedInWithRedirect();

        const session = this.authenticationService.getSession();
        this.roles = session.company.roles;
        this.modules = session.company.modules;

        this.userPolicyForm = this.formBuilder.group({
            role: ["", Validators.required],
            modules: this.formBuilder.group({}),
        });
        
        const role = this.route.snapshot.paramMap.get("role");

        this.userPolicyService.getUserPolicy(role).subscribe((userPolicyData: any) => {
            if (userPolicyData.message == "success") {
                this.userPolicy = userPolicyData.res.userPolicy;

                this.userPolicyForm.patchValue({ role: this.userPolicy.role});

                const modulesFormGroup = this.userPolicyForm.get("modules") as FormGroup;
                this.modules.forEach((moduleVal: string) => {
                    const menuItemChecked = this.userPolicy.menuItems.includes(moduleVal);

                    modulesFormGroup.addControl(
                        moduleVal,
                        this.formBuilder.control(menuItemChecked)
                    );
                });
            } else {
                this.snackBar.open("Error fetching User Policy data", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });
            }
        });
    }

    // #=========== ---- =========== Edit User Policy Component onSubmit =========== ---- ===========# //
    onSubmit(): void {
        if (this.userPolicyForm.invalid) {
            this.snackBar.open("Please fill in all required fields", "Close", {
                verticalPosition: "top",
                duration: 2000,
            });
            return;
        }

        const userPolicyData = this.userPolicyForm.value;
        let checkedModules = userPolicyData.modules;

        checkedModules = Object.keys(checkedModules).filter(
            (moduleVal: string) => checkedModules[moduleVal]
        );

        userPolicyData.menuItems = checkedModules;
        delete userPolicyData.modules;

        this.userPolicyService
            .updateUserPolicy(this.userPolicy._id, userPolicyData)
            .subscribe(
                (data: any) => {
                    if (data.message == "success") {
                        this.snackBar.open("User Policy updated successfully", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                        this.router.navigate(["/user-policies"]);
                    } else {
                        this.snackBar.open("Error updating User Policy", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }
                },
                (error) => {
                    if (error.status === 400) {
                        this.snackBar.open("User Policy with this role Already Exists, Update it instead", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    } else {
                        this.snackBar.open("Error updating User Policy, try again later", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }

                    this.router.navigate(["/user-policies"]);
                }
            );
    }

    // #=========== ---- =========== Edit User Policy Component onDelete =========== ---- ===========# //
    onDelete(): void {
        this.userPolicyService
            .deleteUserPolicy(this.userPolicy._id)
            .subscribe((data: any) => {
                if (data.message == "success") {
                    this.snackBar.open("User Policy deleted successfully", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });

                    this.router.navigate(["/user-policies"]);
                } else {
                    this.snackBar.open("Error deleting User Policy", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });
                }
            });
    }
}