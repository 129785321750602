import { Component, OnInit } from '@angular/core';
import * as mustache from 'mustache';
import * as async from 'async';
import * as XLSX from 'xlsx';
import { SmsService } from '../services/sms.service';
@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit
{
  public smsRecipients = [];
  public chosenLanguage = "english";
  public textAreaPlaceHolder = "Enter message here";
  public direction = "ltr";
  public smsText = "";
  public errorMessage = "";
  public senderId = "";

  /*
    عزيزي {{name}}
أعلنت وزاره الصحه المصريه عن ادراج عقار الاكريليزوماب اكريفوس (٣٠٠ مج/١٠ مل) لعلاج مرض التصلب المتعدد بنوعيه الانتكاسي و التقدمي بدايه من شهر ابريل تحت مظله التأمين الصحي و نفقه الدوله طبقا للبرتوكول

  */
 // to personalize a message Dear {{name}}, rest of message and the comma is necessary for the rest of message to appear
  constructor(public smsService: SmsService) { }

  ngOnInit() {
  }

  onFileChange(evt: any)
  {
    if(this.senderId == "" || this.senderId == null)
    {
      this.errorMessage = "Please enter a valid sender ID first!";
      return;
    }
    if(this.smsText == "" || this.smsText == null)
    {
      this.errorMessage = "Please enter SMS text first!";
      return;
    }
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) =>
    {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      try
      {
        const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
        // @ts-ignore
        let currentSheet = (XLSX.utils.sheet_to_json(ws));
        console.log(currentSheet);
        this.smsRecipients = currentSheet;
        this.createSMSContent(this.smsRecipients);
      }
      catch(err)
      {
      }







    };
    reader.readAsBinaryString(target.files[0]);
  }

  private createSMSContent(smsRecipients: any[]): void
  {
    //the file to be uploaded must contain "name" and "mobile"
    for(let i = 0; i < smsRecipients.length; i++)
    {
      let content = mustache.render(this.smsText, {name: smsRecipients[i].name});
      smsRecipients[i].text = content;
      smsRecipients[i].status = "Not Sent";
    }
  }



  private sendToAll()
  {
    let currentUser = 0 ;
    let interval = setInterval(()=>{
      let element = this.smsRecipients[currentUser] ;
      if(this.chosenLanguage == "english")
      {
         this.smsService.sendSms(1, this.senderId, element.mobile, element.text).subscribe(response => {
           
          console.log(response)
          if(response.code == "1901" || response.code == 1901)
          {
            element.status = "Sent";
          }
          else
          {

          }

        }, err => {
          console.log(err)
        });
      }
      else
      {
        this.smsService.sendSms(2, this.senderId, element.mobile, element.text).subscribe(response => {
            console.log(response)
            if(response.code == "1901" || response.code == 1901)
            {

              element.status = "Sent";
            }
            else
            {

            }
        }, err => {

          console.log(err)
        });
      }
      currentUser++ ;
      if(currentUser == this.smsRecipients.length){
        clearInterval(interval)
      }
    },2000)

 
    // async.waterfall(this.smsRecipients, (element, callback) => {
    //   console.log(element);
    //   if(this.chosenLanguage == "english")
    //   {
    //      this.smsService.sendSms(1, this.senderId, element.mobile, element.text).subscribe(response => {
    //       console.log(response)
    //       if(response.code == "1901" || response.code == 1901)
    //       {
    //         callback(null);
    //         element.status = "Sent";
    //       }
    //       else
    //       {

    //       }

    //     }, err => {
    //       console.log(err)
    //       callback(err);
    //     });
    //   }
    //   else
    //   {
    //     this.smsService.sendSms(2, this.senderId, element.mobile, element.text).subscribe(response => {
    //         console.log(response)
    //         if(response.code == "1901" || response.code == 1901)
    //         {
    //           callback(null);
    //           element.status = "Sent";
    //         }
    //         else
    //         {
    //           callback(response);
    //         }
    //     }, err => {
    //       callback(err);
    //       console.log(err)
    //     });
    //   }


    // }, () => {
    //   //Done sending all
    //   console.log("Done Sending SMS");
    // })
  }


  public onSendClick(): void
  {
    this.sendToAll();
  }


  public changeLanguage(language: string): void
  {
    if(language == "english")
    {
      this.chosenLanguage = "english";
      this.textAreaPlaceHolder = "Enter message here";
      this.direction = "ltr";
    }
    else
    {
      this.chosenLanguage = "arabic";
      this.textAreaPlaceHolder = "أدخل الرسالة هنا";
      this.direction = "rtl";
    }
  }

}
