// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { UserService } from "src/app/services/user.service";
import { CountryService } from "../../../services/country.service";
import { LineService } from "../../../services/line.service";

// #=========== ---- =========== Create User Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.css"],
})

// #=========== ---- =========== Create User Component =========== ---- ===========# //
export class CreateUserComponent implements OnInit {
  userForm: FormGroup;
  assignProductsForm: FormGroup;

  user: any;
  categories = ["A", "B"];
  countries: Set<string>;
  countriesData: any;
  provinces: Set<string>;
  workingProvinces: string[];
  cities: Set<string>;
  roles = [
    "Sales Rep",
    "Supervisor",
    "Promoter",
    "Marketing",
    "HR",
    "Trainer",
    "Manager",
  ];
  lines: any[];
  supervisors: any[];

  users: any[];
  selectedRole: string;

  // #=========== ---- =========== Create User Component Constructor =========== ---- ===========# //
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private countryService: CountryService,
    private lineService: LineService
  ) {}

  // #=========== ---- =========== Create User Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();

    this.userForm = this.formBuilder.group({
      name: ["", Validators.required],
      username: ["", Validators.required],
      password: ["", Validators.required],
      email: ["", [Validators.required]],
      phone: ["", Validators.required],
      lines: this.formBuilder.array([]),
      category: ["", Validators.required],
      country: ["", Validators.required],
      province: ["", Validators.required],
      city: ["", Validators.required],
      role: ["", Validators.required],
      supervisor: ["", Validators.required],
      workingProvinces: this.formBuilder.array([]),
      isRegional: ["", Validators.required],
      shiftedLeaves: this.formBuilder.group({
        annual: [0, Validators.required],
        casual: [0, Validators.required],
      }),
    });

    this.userForm.get("role").valueChanges.subscribe((role: string) => {
      this.selectedRole = role;
    });

    this.userForm.get("country").valueChanges.subscribe((country: string) => {
      let result = this.countryService.processCountries(
        this.countriesData,
        country
      );
      this.countries = result.countries;
      this.provinces = result.provinces;
      this.workingProvinces = Array.from(result.provinces);
      this.cities = result.cities;

      this.populateFormArray("workingProvinces", this.workingProvinces);
    });

    this.assignProductsForm = this.formBuilder.group({
      selectedProducts: this.formBuilder.array([]),
    });

    // Get Company Lines
    this.lineService.getLines().subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.lines = data.res.lines;

          this.populateFormArray("lines", this.lines);
        }
      },
      (error) => {
        this.snackBar.open("Getting lines failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
        this.router.navigateByUrl("/users");
      }
    );

    // Get Company Countries
    this.countryService.getCountries().subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.countriesData = data.res.countries;
          let result = this.countryService.processCountries(this.countriesData);
          this.countries = result.countries;
          this.provinces = result.provinces;
          this.workingProvinces = Array.from(result.provinces);
          this.cities = result.cities;

          this.populateFormArray("workingProvinces", this.workingProvinces);
        }
      },
      (error) => {
        this.snackBar.open("Getting countries failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
        this.router.navigateByUrl("/users");
      }
    );

    // Get Company Supervisors
    this.userService.getCompanySupervisors().subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.supervisors = data.res.companySupervisors;
        }
      },
      (error) => {
        this.snackBar.open("Getting supervisors failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
        this.router.navigateByUrl("/users");
      }
    );
  }

  // #=========== ---- =========== Create User Component Methods =========== ---- ===========# //
  // #=========== ---- =========== Populate Form Array =========== ---- ===========# //
  populateFormArray(formArrayName: string, allValues: any[]): void {
    const formArray = this.userForm.get(formArrayName) as FormArray;
    formArray.reset(); // Clear existing controls
    allValues.forEach(value => { formArray.push(this.formBuilder.control(false)); });
  }

  // #=========== ---- =========== Create User Method =========== ---- ===========# //
  createUser(): void {
    if (this.userForm.valid) {
      let user = this.userForm.value;
      let isValidLine = this.validateLine(user);
      if (isValidLine) {
        if (user.country != "" && user.country != "No Country") {
          user.lines = this.getSelectedLines();
          user.workingProvinces = this.getSelectedWorkingProvinces();
          this.userService.createUser(user).subscribe(
            (data: any) => {
              if (data.message == "success") {
                this.router.navigateByUrl("/users");
                this.snackBar.open("User created successfully", "Close", {
                  verticalPosition: "top",
                  duration: 2000,
                });
              }
            },
            (error) => {
              this.snackBar.open("User creation failed.", "Close", {
                verticalPosition: "top",
                duration: 2000,
              });
            }
          );
        } else {
          this.snackBar.open("Please enter valid country.", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
        }
      } else {
        this.snackBar.open("Please fill user lines.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    } else {
      this.snackBar.open(
        "Please fill in all required fields, marked red",
        "Close",
        {
          verticalPosition: "top",
          duration: 2000,
        }
      );
    }
  }

  // #=========== ---- =========== Validate Line Method =========== ---- ===========# //
  public validateLine(user) {
    // Line Validation
    if (user.role == "HR") {
      delete user.line;
    } else {
      if (user.lines.length == 0) {
        return false;
      }
    }

    return true;
  }

  // #=========== ---- =========== Get Selected Lines Method =========== ---- ===========# //
  getSelectedLines(): any[] {
    const selectedLines = this.userForm.get('lines') as FormArray;
    return selectedLines.controls
      .map((control, i) => control.value ? this.lines[i]._id : null)
      .filter(value => value !== null);
  }

  // #=========== ---- =========== Get Selected Working Provinces Method =========== ---- ===========# //
  getSelectedWorkingProvinces(): any[] {
    const selectedWorkingProvinces = this.userForm.get('workingProvinces') as FormArray;
    return selectedWorkingProvinces.controls
      .map((control, i) => control.value ? this.workingProvinces[i] : null)
      .filter(value => value !== null);
  }
}
