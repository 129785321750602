// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { LeaveManagementTemplateService } from "src/app/services/leave-management-template.service";
import { CountryService } from "src/app/services/country.service";

// #=========== ---- =========== Create Leave Management Template Component Decorator =========== ---- ===========# //
@Component({
    selector: "app-create-leave-management-template",
    templateUrl: "./create-leave-management-template.component.html",
})

// #=========== ---- =========== Create Leave Management Template Component Class ===========
export class CreateLeaveManagementTemplateComponent implements OnInit{
    countries: any[] = [];
    leaveManagementTemplateForm: FormGroup;
    
    validLeaveTypes: string[];
    noDaysLeaveTypes: string[];

    allLeaveTypes: string[];

    // #=========== ---- =========== Edit Leave Management Template Component Constructor =========== ---- ===========# //
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private leaveManagementTemplateService: LeaveManagementTemplateService,
        private countryService: CountryService,
    ) {}

    // #=========== ---- =========== Create Leave Management Template Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authenticationService.isLoggedInWithRedirect();
 
        const session = this.authenticationService.getSession();
        this.validLeaveTypes = session.company.leaveTypes;
        this.noDaysLeaveTypes = session.company.noDaysLeaveTypes;
        this.allLeaveTypes = this.validLeaveTypes.concat(this.noDaysLeaveTypes);

        this.countryService.getCountries().subscribe(
            (data: any) => {
                if (data.message == "success") {
                    this.countries = data.res.countries;
                } else {
                    this.snackBar.open("Error fetching countries, try again later", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });

                    this.router.navigate(["/leave-management-templates"]);
                }
            },
            (error) => {
                this.snackBar.open("Error fetching countries, try again later", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });

                this.router.navigate(["/leave-management-templates"]);
            }
        );

        this.leaveManagementTemplateForm = this.formBuilder.group({
            country: ["", Validators.required],
            category: ["", Validators.required],
            leaves: this.formBuilder.array([]),
        });

        this.addLeaveGroup();
    }

    // #=========== ---- =========== Form Arrays Helper Methods =========== ---- ===========# //
    createLeaveGroup(
        type: string,
        days: string,
        hrApproval: boolean,
        supervisorApproval: boolean
    ): FormGroup {
        const leaveGroup = this.formBuilder.group({
            leaveType: [type, Validators.required],
            leaveDays: [
                { value: days, disabled: this.noDaysLeaveTypes.includes(type) },
                this.noDaysLeaveTypes.includes(type) ? null : [Validators.required, Validators.pattern("^[0-9]*$")],
            ],
            hrApproval: [hrApproval, Validators.required],
            supervisorApproval: [supervisorApproval, Validators.required],
        });

        leaveGroup.get("leaveType").valueChanges.subscribe((value: string) => {
            const leaveDaysControl = leaveGroup.get("leaveDays");

            if (this.noDaysLeaveTypes.includes(value)) {
                leaveDaysControl.disable();
                leaveDaysControl.clearValidators();
            } else {
                leaveDaysControl.enable();
                leaveDaysControl.setValidators([
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                ]);
            }

            leaveDaysControl.updateValueAndValidity();
        });

        return leaveGroup;
    }

    addLeaveGroup(
        // Added default values to the parameters to call it in the html template without passing arguments
        type: string = "",
        days: string = "",
        hrApproval: boolean = false,
        supervisorApproval: boolean = false
    ): void {
        const leaveGroup = this.createLeaveGroup(type, days, hrApproval, supervisorApproval);
        const leaveArray = this.leaveManagementTemplateForm.get("leaves") as FormArray;
        leaveArray.push(leaveGroup);
    }

    getLeaveGroups(): FormArray {
        return this.leaveManagementTemplateForm.get("leaves") as FormArray;
    }

    // #=========== ---- =========== Edit Leave Management Template Component onDeleteLeaveGroup =========== ---- ===========# //
    onDeleteLeaveGroup(index: number): void {
        const leaveArray = this.leaveManagementTemplateForm.get("leaves") as FormArray;
        leaveArray.removeAt(index);
    }

    // #=========== ---- =========== Create Leave Management Template Component onSubmit =========== ---- ===========# //
    onSubmit(): void {
        if (this.leaveManagementTemplateForm.invalid) {
            this.snackBar.open("Please fill in all required fields", "Close", {
                verticalPosition: "top",
                duration: 2000,
            });

            return;
        }
        const countryId = this.leaveManagementTemplateForm.value.country;

        const formData = {};
        formData["category"] = this.leaveManagementTemplateForm.value.category;
        
        const leaves = [];
        const hrApprovals = [];
        const supervisorApprovals = [];

        this.getLeaveGroups().value.forEach((leave: any) => {
            const leaveObj: any = {
                type: leave.leaveType,
            };
        
            if (!this.noDaysLeaveTypes.includes(leave.leaveType)) {
                leaveObj.days = Number(leave.leaveDays);
            }
        
            leaves.push(leaveObj);

            if (leave.hrApproval) {
                hrApprovals.push(leave.leaveType);
            }

            if (leave.supervisorApproval) {
                supervisorApprovals.push(leave.leaveType);
            }
        });

        formData["leaves"] = leaves;
        formData["hrApprovals"] = hrApprovals;
        formData["supervisorApprovals"] = supervisorApprovals;

        this.leaveManagementTemplateService
            .createLeaveManagementTemplate(countryId, formData)
            .subscribe(
                (data: any) => {
                    if (data.message == "success") {
                        this.snackBar.open("Leave management template created successfully", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });

                        this.router.navigate(["/leave-management-templates"]);
                    } else {
                        this.snackBar.open("Error creating leave management template", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }
                },
                (error) => {
                    this.snackBar.open("Error creating leave management template", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });
                }
            );
    }
}