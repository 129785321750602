import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthenticationService } from "./authentication.service";
import { VisitService } from "./visit.service";
import { SettingsService } from "./settings.service";
import * as XLSX from "xlsx";

import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class ReportsService {
  constructor(
    private authService: AuthenticationService,
    private visitService: VisitService,
    private settingsService: SettingsService
  ) {}

  public outputFileWithMultipleWorksheets(
    data,
    filename,
    worksheetsNames
  ): void {
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    for (let i = 0; i < worksheetsNames.length; i++) {
      /* generate worksheet */
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data[i]);
      XLSX.utils.book_append_sheet(wb, ws, worksheetsNames[i]);
    }

    /* save to file */
    XLSX.writeFile(wb, filename + ".xlsx");
  }

  public outputFileWithOneWorksheet(data, filename, worksheetName): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, worksheetName);

    /* save to file */
    XLSX.writeFile(wb, filename + ".xlsx");
  }

  public outputFileWithTwoWorksheets(
    data,
    secondData,
    filename,
    worksheetName,
    workSheetTwoName
  ): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const wsTwo: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(secondData);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, worksheetName);
    XLSX.utils.book_append_sheet(wb, wsTwo, workSheetTwoName);

    /* save to file */
    XLSX.writeFile(wb, filename + ".xlsx");
  }

  //....................... Helper Functions ....................//

  public convertArrayToCSV(arr): string {
    let result = "";
    for (let i = 0; i < arr.length; i++) {
      if (i == arr.length - 1) {
        result += arr[i];
      } else {
        result += arr[i] + ", ";
      }
    }
    return result;
  }

  public calculateHorizontalTotal(arr): number {
    let result = 0;
    for (let i = 0; i < arr.length; i++) {
      if (!isNaN(arr[i]) && arr[i] != "") {
        result += parseInt(arr[i]);
      }
    }
    return result;
  }

  public calculateHorizontalTotalBetween(arr, lowIndex, highIndex): number {
    let result = 0;
    for (let i = lowIndex; i <= highIndex; i++) {
      if (!isNaN(arr[i]) && arr[i] != "") {
        result += parseInt(arr[i]);
      }
    }
    return result;
  }
}
