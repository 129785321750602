import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { EmailService } from '../../services/email.service';
import { MatSnackBar } from '@angular/material';
import { Moment } from 'moment';
import * as  moment from 'moment';
import * as momentTime from 'moment-timezone';
import * as juice from 'juice';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.css']
})
export class EditEmailComponent implements OnInit {
  email: any;
  emailStatus: string;
  emailVersion: number;
  emailNote: string;
  scheduleTime: Moment;
  scheduleHour: number;
  companyId: string = "";
  emailId: String = "";
  content:  String | null;
  statusOptions = ["Initiated","Approved", "Sending","Sent", "Failed", "Scheduled"]
  errorMessage: string = '';
  emailsFromSheet = [];
  loading = false;
  fromSheetOnly: boolean = false;
  profilesNumber: number;

  constructor(
    private authService: AuthenticationService,
    public activatedRoute: ActivatedRoute,
    public emailService: EmailService,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.authService.isLoggedInWithRedirect();
    this.companyId = this.authService.getCompanyId();
    this.emailId = this.activatedRoute.snapshot.paramMap.get("emailId");
    this.fetchEmailData();
  }

  fetchEmailData() {
    this.emailService.findOne(this.activatedRoute.snapshot.paramMap.get('emailId'))
      .subscribe(data => {
        if (data.status === 'success') {
          this.email = data.response;
          this.emailStatus = this.email.status || '';
          this.emailVersion = this.email.version || 0;
          this.emailNote = this.email.note || '';
          this.scheduleTime = this.email.scheduleTime;
          this.scheduleHour = this.email.scheduleHour;
          this.content = this.email.body;
          this.profilesNumber = this.email.profilesNumber || 0;
        }
      });
  }

  saveStatus() {
    const updatedEmail = { status: this.emailStatus, version: this.emailVersion, note: this.emailNote, body: this.content };
    this.updateEmail(this.emailId, updatedEmail, 'Status');
  }

  saveSchedule() {
    let recipientsList = [];
    this.loading = true;
    console.log("type", typeof this.scheduleTime)
    console.log("value", this.scheduleTime)
    this.scheduleTime = moment(this.scheduleTime);
    console.log("type", typeof this.scheduleTime)
    console.log("value", this.scheduleTime)
    const formattedScheduleTime = this.scheduleTime ? this.scheduleTime.format('YYYY-MM-DD') : null;

    // Validation checks
    if (formattedScheduleTime && this.scheduleHour !== null && this.isValidSchedule()) {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const dateLocal = `${formattedScheduleTime}T${this.scheduleHour}:00:00Z`
      const localTime = momentTime(dateLocal, 'YYYY-MM-DDTHH:mm:ss').tz(localTimeZone);
      const utcTime = localTime.utc();

      if (this.fromSheetOnly) {
        for (let i = 0; i < this.emailsFromSheet.length; i++) {
          let element = this.emailsFromSheet[i];
          let recipient = {
            name: element.Name,
            firstName: element.FirstName,
            lastName: element.LastName,
            email: element.Email,
            class: element.Class,
            specialty: element.Specialty,
            location: {
              country: element.Country,
              province: element.Province,
              city: element.City
            },
            center: element.Center,
            sentTimestamp: Date.now()
          }
          recipientsList.push(recipient);
        }
      }
      const updatedSchedule = { recipients: recipientsList, scheduleTime: formattedScheduleTime, scheduleTimeUtc: utcTime.toDate(), scheduleHour: this.scheduleHour };

      this.updateEmail(this.emailId, updatedSchedule, 'Scheduled time');
    }
  }

  updateEmail(emailId, updatedData: any, logMessage: string) {
    console.log(updatedData);
    this.emailService.update(emailId, updatedData)
      .subscribe(data => {
        if (data.status === 'success') {
          console.log('Email Updated!');
          this.snackbar.open('Email Updated!', 'Close', { verticalPosition: 'top', duration: 2000 });
          this.fetchEmailData(); // Refresh email data after update
        }
      });
    console.log(`${logMessage} saved:`, updatedData);
  }

  isValidSchedule(): boolean {
    const currentDateTime = this.formatToLocal(new Date());
    console.log("currentDateTime", currentDateTime);
    const selectedDateTime = this.scheduleTime ? this.scheduleTime.format('YYYY-MM-DD') : null;
    console.log("selectedDateTime", selectedDateTime);
    // Check if the selected date is in the future
    if (selectedDateTime && selectedDateTime < currentDateTime) {
      this.showErrorMessage('Invalid schedule date: should be in the future');
      return false;
    }

    // Check if scheduleHour is within the valid range (0 to 23)
    if (this.scheduleHour === null || this.scheduleHour < 0 || this.scheduleHour > 23 || this.scheduleHour.toString().length > 2) {
      this.showErrorMessage('Invalid schedule hour: should be between 0 and 23');
      return false;
    }
    // Check if the scheduled time is in the future
    const scheduledTime = new Date(selectedDateTime);
    scheduledTime.setHours(this.scheduleHour, 0, 0, 0);

    if (scheduledTime <= new Date()) {
      this.showErrorMessage('Invalid schedule time: should be in the future');
      return false;
    }
    return true;
  }

  showErrorMessage(message: string): void {
    // Display error message using MatSnackBar
    this.errorMessage = message;
    this.snackbar.open(message, 'Close', { verticalPosition: 'top', duration: 3000, panelClass: ['error-snackbar'] });
  }

  formatToLocal(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  inlineCSS(htmlString): String {
    return juice(htmlString);
  }
  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

      try {
        const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
        // @ts-ignore
        let currentSheet = XLSX.utils.sheet_to_json(ws, {defval: ""});
        // console.log(currentSheet);

      // Validate column names
      const columnNames: string[] = Object.keys(currentSheet[0]);
      console.log("columnNames", columnNames);
      const expectedColumnNames: string[] = ['#', 'Name', 'FirstName', 'Email', 'Specialty', 'Class', 'Country', 'Province', 'City', 'Center'];
      const isValidColumns: boolean = expectedColumnNames.every(column => columnNames.includes(column));
      console.log("isValidColumns", isValidColumns);
      if (!isValidColumns) {
        this.snackbar.open("Columns in the file do not match expected column names: ['#', 'Name', 'FirstName', 'Email', 'Specialty', 'Class', 'Country', 'Province', 'City', 'Center'].", 'Close', {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: ['error-snackbar']
        });
        evt.target
        evt.target.value = '';
        return;
      }
        this.emailsFromSheet = currentSheet;
      } catch(err) {
        console.log(err);
      }
    };

    reader.readAsBinaryString(target.files[0]);
  }
}
