// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { CallExpirationService } from "../../../services/call-expiration.service";
import { CountryService } from "src/app/services/country.service";

// #=========== ---- =========== Create Call Expiration Component Decorator =========== ---- ===========# //
@Component({
    selector: "app-create-call-expiration",
    templateUrl: "./create-call-expiration.component.html",
})

// #=========== ---- =========== Create Call Expiration Component =========== ---- ===========# //
export class CreateCallExpirationComponent implements OnInit {
    callExpirationForm: FormGroup;
    callExpiration: any;

    countries: any[];
    
    // #=========== ---- =========== Create Call Expiration Component Constructor =========== ---- ===========# //
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private callExpirationService: CallExpirationService,
        private countryService: CountryService
    ) {}

    // #=========== ---- =========== Create Call Expiration Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authenticationService.isLoggedInWithRedirect();

        this.callExpirationForm = this.formBuilder.group({
            countryId: ["", Validators.required],
            expirationDays: ["", Validators.required],
        });

        this.countryService.getCountries().subscribe((countryData: any) => {
            if (countryData.message == "success") {
                this.countries = countryData.res.countries;
            } else {
                this.snackBar.open("Failed to get countries", "Close", {
                    verticalPosition: "top",
                    duration: 2000,
                });
                this.router.navigateByUrl("/call-expirations");
            }
        });
    }

    // #=========== ---- =========== Create Call Expiration Component onSubmit =========== ---- ===========# //
    onSubmit(): void {
        if (this.callExpirationForm.invalid) {
            this.snackBar.open("Please fill in all required fields", "Close", {
                verticalPosition: "top",
                duration: 2000,
            });
            return;
        }

        this.callExpirationService.createCallExpiration(this.callExpirationForm.value).subscribe(
            (data: any) => {
                if (data.message == "success") {
                    this.snackBar.open("Call expiration created successfully", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });
                } else {
                    this.snackBar.open("Failed to create call expiration", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });                
                }
                
                this.router.navigateByUrl("/call-expirations");
            },

            (error) => {
                if (error.status === 400) {
                    this.snackBar.open("Call Expiration Already Exists, Update it instead", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });
    
                } else {
                    this.snackBar.open("Failed to create call expiration, try again later", "Close", {
                        verticalPosition: "top",
                        duration: 2000,
                    });

                }
                this.router.navigateByUrl("/call-expirations");
            }
        );
    }
}