// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import External Libraries =========== ---- ===========# //
import { switchMap } from "rxjs/operators";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { SettingsService } from "../services/settings.service";
import { CompanyService } from "../services/company.service";

// #=========== ---- =========== Login Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})

// #=========== ---- =========== Login Component =========== ---- ===========# //
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  loginForm: FormGroup;
  @ViewChild("loginEl") loginRef: ElementRef;

  // #=========== ---- =========== Login Constructor =========== ---- ===========# //
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Login ngOnInit =========== ---- ===========# //
  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
    });
  }

  // #=========== ---- =========== onError Method =========== ---- ===========# //
  onError(errorNumber) {
    // now access the form through
    let form = this.loginRef.nativeElement;
    form.classList.add(errorNumber);
    setTimeout(() => {
      form.classList.remove(errorNumber);
    }, 3000);
  }

  // #=========== ---- =========== Login Method =========== ---- ===========# //
  public onLogin() {
    this.username = this.loginForm.value.email;
    this.password = this.loginForm.value.password;
    if (this.loginForm.get("email").invalid) {
      this.onError("error_3");
    }
    if (this.loginForm.get("password").invalid) {
      this.onError("error_4");
    }
    this.authService.login(this.username, this.password).subscribe((data) => {
      if (data.message == "success") {
        this.router.navigateByUrl("/users");
      } else {
        this.onError("error_1");
      }
    });
  }
}
