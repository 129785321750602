// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import {
  MatSnackBar,
  MatTabChangeEvent,
  MatTableDataSource,
} from "@angular/material";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../services/authentication.service";
import { CountryService } from "../services/country.service";

// #=========== ---- =========== Location Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.css"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})

// #=========== ---- =========== Location Component =========== ---- ===========# //
export class LocationsComponent implements OnInit {
  // Creation and Deletion State
  locationTypes = ["Country", "Province", "City/Cities"];
  selectedType: string;
  action: string = "Add";

  name: string;
  country: any;
  province: any;

  // State for drop down menus
  countries: any[];
  provinces: any[];
  cities: any[];

  // State for displaying locations
  countriesDisplayed: MatTableDataSource<any>;
  displayedColumns: string[] = ["Countries"];
  expandedElement: any;

  // #=========== ---- =========== Locations Component Constructor =========== ---- ===========# //
  constructor(
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private countryService: CountryService
  ) {}

  // #=========== ---- =========== Locations Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();

    this.countryService.getCountries().subscribe(
      (data) => {
        if (data.message == "success") {
          this.countries = data.res.countries;
          this.countriesDisplayed = new MatTableDataSource(data.res.countries);
        }
      },
      (error) => {
        this.snackBar.open("Getting countries failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    );
  }

  // #=========== ---- =========== Locations Component Methods =========== ---- ===========# //

  // #=========== ---- =========== On Tab Change Method =========== ---- ===========# //
  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.action = "Add";
    } else if (event.index === 1) {
      this.action = "Delete";
    }
  }

  // #=========== ---- =========== On Country Select Method =========== ---- ===========# //
  onCountrySelect() {
    const country = this.countries.find(
      (country: any) => country.name === this.country
    );
    this.provinces = country.provinces;
  }

  // #=========== ---- =========== On Province Select Method =========== ---- ===========# //
  onProvinceSelect() {
    const country = this.countries.find(
      (country: any) => country.name === this.country
    );
    const province = country.provinces.find(
      (province: any) => province.name === this.province
    );

    this.cities = province.cities;
  }

  // #=========== ---- =========== Handle Country Method =========== ---- ===========# //
  handleCountry(action: string) {
    switch (this.action) {
      case "Add":
        this.countryService
          .createCountry(this.name)
          .subscribe((country: any) => {
            if (country) {
              this.countries.push(country);
              this.countriesDisplayed = new MatTableDataSource(this.countries);

              this.snackBar.open("Country added successfully", "Close", {
                verticalPosition: "top",
                duration: 3000,
              });
            } else {
              this.snackBar.open("Failed to add country", "Close", {
                verticalPosition: "top",
                duration: 3000,
              });
            }
          });
        break;
      case "Delete":
        const country = this.countries.find(
          (country: any) => country.name === this.name
        );

        this.countryService
          .deleteCountry(country._id)
          .subscribe((country: any) => {
            this.countries = this.countries.filter(
              (country: any) => country.name !== this.name
            );
            this.countriesDisplayed = new MatTableDataSource(this.countries);

            this.snackBar.open("Country deleted successfully", "Close", {
              verticalPosition: "top",
              duration: 3000,
            });
          });
        break;
    }
  }

  // #=========== ---- =========== Handle Province Method =========== ---- ===========# //
  handleProvince(action: string) {
    const country = this.countries.find(
      (country: any) => country.name === this.country
    );

    switch (this.action) {
      case "Add":
        this.countryService
          .createProvince(this.name, country._id)
          .subscribe((data: any) => {
            if (data) {
              const index = this.countries.findIndex(
                (country: any) => country.name === this.country
              );
              this.countries[index].provinces.push(
                data.provinces[data.provinces.length - 1]
              );
              this.countriesDisplayed = new MatTableDataSource(this.countries);

              this.snackBar.open("Province added successfully", "Close", {
                verticalPosition: "top",
                duration: 3000,
              });
            } else {
              this.snackBar.open("Failed to add province", "Close", {
                verticalPosition: "top",
                duration: 3000,
              });
            }
          });
        break;
      case "Delete":
        this.countryService
          .deleteProvince(this.name, country._id)
          .subscribe((province: any) => {
            const index = this.countries.findIndex(
              (country: any) => country.name === this.country
            );
            this.countries[index].provinces = this.countries[
              index
            ].provinces.filter((province: any) => province.name !== this.name);
            this.countriesDisplayed = new MatTableDataSource(this.countries);

            this.snackBar.open("Province deleted successfully", "Close", {
              verticalPosition: "top",
              duration: 3000,
            });
          });
        break;
    }
  }

  // #=========== ---- =========== Handle City Method =========== ---- ===========# //
  handleCity(action: string) {
    const country = this.countries.find(
      (country: any) => country.name === this.country
    );
    const province = country.provinces.find(
      (province: any) => province.name === this.province
    );

    switch (this.action) {
      case "Add":
        const cities = this.name.split(",").map((city: string) => city.trim());
        this.countryService
          .createCities(cities, province.name, country._id)
          .subscribe((data: any) => {
            if (data) {
              const countryIndex = this.countries.findIndex(
                (country: any) => country.name === this.country
              );
              const provinceIndex = this.countries[
                countryIndex
              ].provinces.findIndex(
                (province: any) => province.name === this.province
              );

              // console.log(city);
              let newCities = data.provinces.filter((prov) => {
                return prov.name == this.province;
              })[0].cities;
              this.countries[countryIndex].provinces[provinceIndex].cities.push(
                newCities[newCities.length - 1]
              );
              this.countriesDisplayed = new MatTableDataSource(this.countries);

              this.snackBar.open("City added successfully", "Close", {
                verticalPosition: "top",
                duration: 3000,
              });
            } else {
              this.snackBar.open("Failed to add city", "Close", {
                verticalPosition: "top",
                duration: 3000,
              });
            }
          });
        break;
      case "Delete":
        this.countryService
          .deleteCity(this.name, province.name, country._id)
          .subscribe((city: any) => {
            const countryIndex = this.countries.findIndex(
              (country: any) => country.name === this.country
            );
            const provinceIndex = this.countries[
              countryIndex
            ].provinces.findIndex(
              (province: any) => province.name === this.province
            );
            this.countries[countryIndex].provinces[provinceIndex].cities =
              this.countries[countryIndex].provinces[
                provinceIndex
              ].cities.filter((city: any) => city.name !== this.name);
            this.countriesDisplayed = new MatTableDataSource(this.countries);

            this.snackBar.open("City deleted successfully", "Close", {
              verticalPosition: "top",
              duration: 3000,
            });
          });
        break;
    }
  }

  // #=========== ---- =========== On Submit Method =========== ---- ===========# //
  onSubmit(): void {
    switch (this.selectedType) {
      case "Country":
        this.handleCountry(this.action);
        break;
      case "Province":
        this.handleProvince(this.action);
        break;
      case "City/Cities":
        this.handleCity(this.action);
        break;
    }
  }
}
