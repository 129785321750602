import { Component, OnInit } from '@angular/core';

import * as grapesjs from 'grapesjs';
import * as nlPlugin from 'grapesjs-preset-newsletter';

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.css']
})
export class EmailEditorComponent implements OnInit {

  constructor() { }

  ngOnInit()
  {
    grapesjs.plugins.add('gjs-preset-newsletter-2', nlPlugin.default);

      var editor = grapesjs.init({
        container : document.getElementById('editor'),
        plugins: ['gjs-preset-newsletter-2'],
        components: '<div class="txt-red">Hello world!</div>',
        pluginsOpts: {
          'gjs-preset-newsletter-2': {
            modalTitleImport: 'Import template',
            // ... other options
          }
        }

    });
  }

}
