// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { ProductService } from "src/app/services/product.service";
import { LineService } from "src/app/services/line.service";
import { CountryService } from "src/app/services/country.service";

// #=========== ---- =========== Edit Line Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-edit-line",
  templateUrl: "./edit-line.component.html",
  styleUrls: ["./edit-line.component.css"],
})

// #=========== ---- =========== Edit Line Component =========== ---- ===========# //
export class EditLineComponent implements OnInit {
  lineForm: FormGroup;

  line: any;
  countries: Set<string>;

  companyProducts: any;

  // #=========== ---- =========== Edit Line Component Constructor =========== ---- ===========# //

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private lineService: LineService,
    private countryService: CountryService,
    private productService: ProductService
  ) {}

  // #=========== ---- =========== Edit Line Component ngOnInit =========== ---- ===========# //

  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();

    this.lineForm = this.formBuilder.group({
      name: ["", Validators.required],
      country: ["", Validators.required],
      selectedProducts: this.formBuilder.array([]),
    });

    const lineId = this.route.snapshot.paramMap.get("lineId");

    this.productService.getCompanyProducts().subscribe((productData: any) => {
      if (productData.message == "success") {
        this.companyProducts = productData.res.products;

        this.lineService.getLine(lineId).subscribe((lineData: any) => {
          if (lineData.message == "success") {
            this.handleLine(lineData.res.line);
            this.companyProducts.forEach((product: any) => {
              if (this.line.products.includes(product._id)) {
                product.isSelected = true;
                (this.lineForm.get("selectedProducts") as FormArray).push(
                  this.formBuilder.control(product._id)
                );
              } else {
                product.isSelected = false;
              }
              return product;
            });
          }
        });
      }
    });

    // Get Company Countries
    this.countryService.getCountries().subscribe((data: any) => {
      if (data.message == "success") {
        this.countries = data.res.countries.map((country: any) => country.name);
      }
    });
  }

  // #=========== ---- =========== Edit Line Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Handle Line Method =========== ---- ===========# //

  private handleLine(line: any): void {
    this.line = line;
    this.lineForm.patchValue(this.line);
  }

  // #=========== ---- =========== Save Line Method =========== ---- ===========# //
  saveLine(): void {
    if (this.lineForm.valid) {
      let line = this.lineForm.value;
      let selectedProducts = (
        this.lineForm.get("selectedProducts") as FormArray
      ).controls.map((control) => control.value);
      line.productsIds = selectedProducts;

      if (selectedProducts.length > 0) {
        const lineId = this.line._id;
        this.lineService.updateLine(lineId, line).subscribe(
          (data: any) => {
            if (data.message == "success") {
              this.handleLine(line);
              this.snackBar.open("Line updated successfully", "Close", {
                verticalPosition: "top",
                duration: 2000,
              });
              this.router.navigateByUrl("/lines");
            }
          },
          (error) => {
            this.snackBar.open("Line update failed.", "Close", {
              verticalPosition: "top",
              duration: 2000,
            });
          }
        );
      } else {
        this.snackBar.open("Please select at least one product.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    } else {
      this.snackBar.open(
        "Please fill in all required fields, marked red",
        "Close",
        {
          verticalPosition: "top",
          duration: 2000,
        }
      );
    }
  }

  // #=========== ---- =========== On Select Product Method =========== ---- ===========# //
  onSelectProduct(event: MouseEvent, product: any): void {
    product.isSelected = !product.isSelected;
    const selectedProducts = this.lineForm.get("selectedProducts") as FormArray;

    if (product.isSelected) {
      selectedProducts.push(this.formBuilder.control(product._id));
    } else {
      const index = selectedProducts.controls.findIndex(
        (control) => control.value === product._id
      );
      if (index !== -1) {
        selectedProducts.removeAt(index);
      }
    }
  }

  // #=========== ---- =========== On Delete Product Method =========== ---- ===========# //
  onDeleteLine(): void {
    let lineId = this.line._id;

    this.lineService.deleteLine(lineId).subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.snackBar.open("Line deleted successfully", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });

          this.router.navigateByUrl("/lines");
        }
      },
      (error) => {
        this.snackBar.open("Line deletion failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    );
  }
}
