// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { forkJoin } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { UserService } from "../../../services/user.service";
import { CountryService } from "../../../services/country.service";
import { LineService } from "../../../services/line.service";

// #=========== ---- =========== Edit User Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})

// #=========== ---- =========== Edit User Component =========== ---- ===========# //
export class EditUserComponent implements OnInit {
  userForm: FormGroup;
  substituteUserForm: FormGroup;
  showPasswordField: boolean = false;

  user: any;
  categories = ["A", "B"];
  countriesData: any;
  countries: Set<string>;
  provinces: Set<string>;
  workingProvinces: any[] = [];
  cities: Set<string>;
  roles = [
    "Sales Rep",
    "Supervisor",
    "Promoter",
    "Marketing",
    "HR",
    "Trainer",
    "Manager",
  ];
  lines: any[];
  supervisors: any[];
  users: any[];
  selectedRole: string;

  // #=========== ---- =========== Edit User Component Constructor =========== ---- ===========# //
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private lineService: LineService,
    private countryService: CountryService
  ) {}

  // #=========== ---- =========== Edit User Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();

    this.userForm = this.formBuilder.group({
      name: ["", Validators.required],
      username: ["", Validators.required],
      password: ["", Validators.required],
      email: ["", [Validators.required]],
      phone: ["", Validators.required],
      lines: this.formBuilder.array([]),
      category: ["", Validators.required],
      country: ["", Validators.required],
      province: ["", Validators.required],
      city: ["", Validators.required],
      role: ["", Validators.required],
      supervisor: ["", Validators.required],
      workingProvinces: this.formBuilder.array([]),
      isRegional: ["", Validators.required],
      isActive: ["", Validators.required],
      shiftedLeaves: this.formBuilder.group({
        annual: [0, Validators.required],
        casual: [0, Validators.required],
      }),
    });

    this.substituteUserForm = this.formBuilder.group({
      substituteUser: ["", Validators.required],
    });

    this.userForm.get("role").valueChanges.subscribe((role: string) => {
      this.selectedRole = role;
    });

    const userId = this.route.snapshot.paramMap.get("userId");

    // Fetch user data first
    this.userService.getUser(userId).subscribe(
      (data) => {
        if (data.message == "success") {
          this.user = data.res.user;
          if (this.user.supervisorIds.length > 0) {
            this.user.supervisor = this.user.supervisorIds[0]._id;
          } else {
            this.user.supervisor = {
              _id: "No Supervisor",
              name: "No Supervisor",
            };
          }
          this.populateFormWithUserData();

          // Fetch the rest of the data
          forkJoin([
            this.lineService.getLines(),
            this.countryService.getCountries(),
            this.userService.getCompanySupervisors(),
            this.userService.getCompanyUsers()
          ]).subscribe(
            ([linesData, countriesData, supervisorsData, usersData]) => {
              if (linesData.message == "success") {
                this.lines = linesData.res.lines;
                this.populateFormArray("lines", this.lines, this.user.lines);
              }

              if (countriesData.message == "success") {
                this.countriesData = countriesData.res.countries;
                let result = this.countryService.processCountries(this.countriesData, this.user.country);
                this.countries = result.countries;
                this.provinces = result.provinces;
                this.cities = result.cities;
                this.workingProvinces = Array.from(result.provinces);
                this.populateFormArray("workingProvinces", this.workingProvinces, this.user.workingProvinces);
              }

              if (supervisorsData.message == "success") {
                supervisorsData.res.companySupervisors.unshift({
                  _id: "No Supervisor",
                  name: "No Supervisor",
                });
                this.supervisors = supervisorsData.res.companySupervisors;
              }

              if (usersData.message == "success") {
                this.users = usersData.res.companyUsers;
              }
            },
            (error) => {
              this.snackBar.open("Fetching data failed.", "Close", {
                verticalPosition: "top",
                duration: 2000,
              });
              this.router.navigateByUrl("/users");
            }
          );
        }
      },
      (error) => {
        this.snackBar.open("Getting user failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
        this.router.navigateByUrl("/users");
      }
    );
  }

  // #=========== ---- =========== Edit User Component Methods =========== ---- ===========# //
  
  // #=========== ---- =========== Populate Form Array =========== ---- ===========# //
  populateFormArray(formArrayName: string, allValues: any[], selectedValues: any[]): void {
    const formArray = this.userForm.get(formArrayName) as FormArray;
    formArray.reset(); // Clear existing controls
    allValues.forEach(value => {
      const isSelected = selectedValues.includes(value._id || value);
      formArray.push(this.formBuilder.control(isSelected));
    });
  }

  // #=========== ---- =========== Populate Form with User Data =========== ---- ===========# //
  populateFormWithUserData(): void {
    this.userForm.patchValue(this.user);
    this.userForm.get("password").reset();
    if (this.user.hasOwnProperty('shiftedLeaves') && this.user.shiftedLeaves !== null) {
      this.userForm.get("shiftedLeaves").patchValue(this.user.shiftedLeaves);
    }
  }

  // #=========== ---- =========== Save User Method =========== ---- ===========# //
  saveUser(): void {
    if (this.userForm.valid) {
      let user = this.userForm.value;
      user._id = this.user._id;

      // Get selected lines
      user.lines = this.getSelectedLines();

      // Get selected working provinces
      user.workingProvinces = this.getSelectedWorkingProvinces();

      // Handle missing fields that are not required in the model
      if (user.supervisor === "No Supervisor") {
        delete user.supervisor;
      }

      // Remove password field if it is not visible
      if (!this.showPasswordField) {
        delete user.password;
      }

      let isValidLine = this.validateLine(user);
      if (isValidLine) {
        if (user.country != "" && user.country != "No Country") {
          this.userService.updateUser(user).subscribe(
            (data: any) => {
              if (data.message == "success") {
                this.router.navigateByUrl("/users");
                this.snackBar.open("User updated successfully", "Close", {
                  verticalPosition: "top",
                  duration: 2000,
                });
              }
            },
            (error) => {
              this.snackBar.open("User update failed.", "Close", {
                verticalPosition: "top",
                duration: 2000,
              });
            }
          );
        } else {
          this.snackBar.open("Please enter valid country.", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
        }
      } else {
        this.snackBar.open("Please fill user lines.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    } else {
      this.snackBar.open("Please fill all the required fields", "Close", {
        verticalPosition: "top",
        duration: 2000,
      });
    }
  }

  // #=========== ---- =========== Get Selected Lines Method =========== ---- ===========# //
  getSelectedLines(): any[] {
    const selectedLines = this.userForm.get('lines') as FormArray;
    return selectedLines.controls
      .map((control, i) => control.value ? this.lines[i]._id : null)
      .filter(value => value !== null);
  }

  // #=========== ---- =========== Get Selected Working Provinces Method =========== ---- ===========# //
  getSelectedWorkingProvinces(): any[] {
    const selectedWorkingProvinces = this.userForm.get('workingProvinces') as FormArray;
    return selectedWorkingProvinces.controls
      .map((control, i) => control.value ? this.workingProvinces[i] : null)
      .filter(value => value !== null);
  }

  // #=========== ---- =========== Validate Line Method =========== ---- ===========# //
  public validateLine(user) {
    // Line Validation
    if (user.role == "HR") {
      delete user.line;
    } else {
      if (user.lines.length == 0) {
        return false;
      }
    }

    return true;
  }

  // #=========== ---- =========== Substitute User Method =========== ---- ===========# //
  substituteUser(): void {
    const substitute = this.substituteUserForm.get("substituteUser").value;
    const substituteId = substitute._id;
    const userId = this.user._id;

    this.userService.substituteUser(userId, substituteId).subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.snackBar.open("User substituted successfully", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
        }
      },
      (error) => {
        this.snackBar.open("User substitution failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    );
  }

  // #=========== ---- =========== Toggle Password Field =========== ---- ===========# //
  togglePasswordField(): void {
    this.showPasswordField = !this.showPasswordField;
    if (!this.showPasswordField) {
      this.userForm.get('password').reset();
    }
  }
}