// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "./authentication.service";

// #=========== ---- =========== Import Environment =========== ---- ===========# //
import { environment } from "../../environments/environment";

// #=========== ---- =========== Testimonial Service =========== ---- ===========# //
@Injectable({
  providedIn: "root",
})
export class TestimonialService {
  private baseUrl: String = `${environment.baseUrlV2}api-v2/testimonials/`;

  // #=========== ---- =========== Testimonial Service Constructor =========== ---- ===========# //
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  // #=========== ---- =========== Testimonial Service Methods =========== ---- ===========# //

  // #=========== ---- =========== Get Testimonials Method =========== ---- ===========# //
  getTestimonials(): Observable<any> {
    return this.http.get(this.baseUrl + "get", {
      headers: { authorization: "Bearer " + this.authService.getToken() },
    });
  }
}
