// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { UserService } from "src/app/services/user.service";
import { CountryService } from "../../../services/country.service";
import { LineService } from "../../../services/line.service";

// #=========== ---- =========== Create User Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.css"],
})

// #=========== ---- =========== Create User Component =========== ---- ===========# //
export class CreateUserComponent implements OnInit {
  userForm: FormGroup;
  assignProductsForm: FormGroup;

  user: any;
  categories = ["A", "B"];
  countries: Set<string>;
  countriesData: any;
  provinces: Set<string>;
  workingProvinces: Set<string>;
  cities: Set<string>;
  roles = [
    "Sales Rep",
    "Supervisor",
    "Promoter",
    "Marketing",
    "HR",
    "Trainer",
    "Manager",
  ];
  lines: any[];
  supervisors: any[];

  users: any[];
  selectedRole: string;

  // #=========== ---- =========== Create User Component Constructor =========== ---- ===========# //
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private countryService: CountryService,
    private lineService: LineService
  ) {}

  // #=========== ---- =========== Create User Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();

    this.userForm = this.formBuilder.group({
      name: ["", Validators.required],
      username: ["", Validators.required],
      password: ["", Validators.required],
      email: ["", [Validators.required]],
      phone: ["", Validators.required],
      lines: this.formBuilder.array([]),
      category: ["", Validators.required],
      country: ["", Validators.required],
      province: ["", Validators.required],
      city: ["", Validators.required],
      role: ["", Validators.required],
      supervisor: ["", Validators.required],
      workingProvinces: this.formBuilder.array([]),
      isRegional: ["", Validators.required],
    });

    this.userForm.get("role").valueChanges.subscribe((role: string) => {
      this.selectedRole = role;
    });

    this.userForm.get("country").valueChanges.subscribe((country: string) => {
      let result = this.countryService.processCountries(
        this.countriesData,
        country
      );
      this.countries = result.countries;
      this.provinces = result.provinces;
      this.workingProvinces = result.workingProvinces;
      this.cities = result.cities;
    });

    this.assignProductsForm = this.formBuilder.group({
      selectedProducts: this.formBuilder.array([]),
    });

    // Get Company Lines
    this.lineService.getLines().subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.lines = data.res.lines;

          this.lines.forEach((line: any) => {
            line.selected = false;
          });
        }
      },
      (error) => {
        this.snackBar.open("Getting lines failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
        this.router.navigateByUrl("/users");
      }
    );

    // Get Company Countries
    this.countryService.getCountries().subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.countriesData = data.res.countries;
          let result = this.countryService.processCountries(this.countriesData);
          this.countries = result.countries;
          this.provinces = result.provinces;
          this.workingProvinces = result.workingProvinces;
          this.cities = result.cities;
        }
      },
      (error) => {
        this.snackBar.open("Getting countries failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
        this.router.navigateByUrl("/users");
      }
    );

    // Get Company Supervisors
    this.userService.getCompanySupervisors().subscribe(
      (data: any) => {
        if (data.message == "success") {
          this.supervisors = data.res.companySupervisors;
        }
      },
      (error) => {
        this.snackBar.open("Getting supervisors failed.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
        this.router.navigateByUrl("/users");
      }
    );
  }

  // #=========== ---- =========== Create User Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Toggle Line Select Method =========== ---- ===========# //
  toggleLineSelect(line: any): void {
    let lines = this.userForm.get("lines") as FormArray;

    let index = lines.controls.findIndex(
      (control: any) => control.value === line._id
    );

    if (index === -1) {
      lines.push(this.formBuilder.control(line._id));
    } else {
      lines.removeAt(index);
    }

    line.selected = !line.selected;
  }

  // #=========== ---- =========== Toggle Province Select Method =========== ---- ===========# //
  toggleProvinceSelect(province: any): void {
    let selectedWorkingProvinces = this.userForm.get(
      "workingProvinces"
    ) as FormArray;

    let index = selectedWorkingProvinces.controls.findIndex(
      (control: any) => control.value === province.name
    );

    if (index === -1) {
      selectedWorkingProvinces.push(this.formBuilder.control(province.name));
    } else {
      selectedWorkingProvinces.removeAt(index);
    }

    province.selected = !province.selected;
  }

  // #=========== ---- =========== Create User Method =========== ---- ===========# //
  createUser(): void {
    if (this.userForm.valid) {
      let user = this.userForm.value;
      let isValidLine = this.validateLine(user);
      if (isValidLine) {
        if (user.country != "" && user.country != "No Country") {
          this.userService.createUser(user).subscribe(
            (data: any) => {
              if (data.message == "success") {
                this.router.navigateByUrl("/users");
                this.snackBar.open("User created successfully", "Close", {
                  verticalPosition: "top",
                  duration: 2000,
                });
              }
            },
            (error) => {
              this.snackBar.open("User creation failed.", "Close", {
                verticalPosition: "top",
                duration: 2000,
              });
            }
          );
        } else {
          this.snackBar.open("Please enter valid country.", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
        }
      } else {
        this.snackBar.open("Please fill user lines.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    } else {
      this.snackBar.open(
        "Please fill in all required fields, marked red",
        "Close",
        {
          verticalPosition: "top",
          duration: 2000,
        }
      );
    }
  }

  // #=========== ---- =========== Validate Line Method =========== ---- ===========# //
  public validateLine(user) {
    // Line Validation
    if (user.role == "HR") {
      delete user.line;
    } else {
      if (user.lines.length == 0) {
        return false;
      }
    }

    return true;
  }
}
