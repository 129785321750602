// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { ProductService } from "../../../services/product.service";
import { LineService } from "../../../services/line.service";
import { CountryService } from "../../../services/country.service";

// #=========== ---- =========== Create Line Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-create-line",
  templateUrl: "./create-line.component.html",
  styleUrls: ["./create-line.component.css"],
})

// #=========== ---- =========== Create Line Component =========== ---- ===========# //
export class CreateLineComponent implements OnInit {
  lineForm: FormGroup;

  line: any;
  countries: Set<string>;

  companyProducts: any[];

  // #=========== ---- =========== Create Line Component Constructor =========== ---- ===========# //
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private lineService: LineService,
    private productService: ProductService,
    private countryService: CountryService
  ) {}

  // #=========== ---- =========== Create Line Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();
    // Line Form
    this.lineForm = this.formBuilder.group({
      name: ["", Validators.required],
      country: ["", Validators.required],
      selectedProducts: this.formBuilder.array([]),
    });

    // Get Company Products
    this.productService.getCompanyProducts().subscribe((data: any) => {
      if (data.message == "success") {
        this.companyProducts = data.res.products.map((product: any) => {
          product.isSelected = false;
          return product;
        });
      } else {
        this.router.navigateByUrl("/lines");
      }
    });

    // Get Company Countries
    this.countryService.getCountries().subscribe((data: any) => {
      if (data.message == "success") {
        this.countries = new Set(
          data.res.countries.map((country: any) => country.name)
        );
      } else {
        this.router.navigateByUrl("/lines");
      }
    });
  }

  // #=========== ---- =========== Create Line Component Methods =========== ---- ===========# //

  createLine(): void {
    if (this.lineForm.valid) {
      let line = this.lineForm.value;
      line.companyId = this.authenticationService.getCompanyId();
      let selectedProducts = (
        this.lineForm.get("selectedProducts") as FormArray
      ).controls.map((control) => control.value);
      if (selectedProducts.length > 0) {
        line.productsIds = selectedProducts;

        this.lineService.createLine(line).subscribe(
          (data: any) => {
            if (data.message == "success") {
              this.line = data.res.line;
              this.lineForm.patchValue(this.line);
              this.snackBar.open("Line created successfully", "Close", {
                verticalPosition: "top",
                duration: 2000,
              });

              this.router.navigateByUrl("/lines");
            }
          },
          (error) => {
            this.snackBar.open("Line creation failed.", "Close", {
              verticalPosition: "top",
              duration: 2000,
            });
          }
        );
      } else {
        this.snackBar.open("Please select at least one product.", "Close", {
          verticalPosition: "top",
          duration: 2000,
        });
      }
    } else {
      this.snackBar.open(
        "Please fill in all required fields, marked red",
        "Close",
        {
          verticalPosition: "top",
          duration: 2000,
        }
      );
    }
  }

  // #=========== ---- =========== On Select Product Methods =========== ---- ===========# //
  onSelectProduct(event: MouseEvent, product: any): void {
    product.isSelected = !product.isSelected;
    const selectedProducts = this.lineForm.get("selectedProducts") as FormArray;

    if (product.isSelected) {
      selectedProducts.push(this.formBuilder.control(product._id));
    } else {
      const index = selectedProducts.controls.findIndex(
        (control) => control.value === product._id
      );
      if (index !== -1) {
        selectedProducts.removeAt(index);
      }
    }
  }
}
