// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// #=========== ---- =========== Import Components =========== ---- ===========# //
import { MatSnackBar } from "@angular/material";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { UserPolicyService } from "src/app/services/user-policy.service";

// #=========== ---- =========== Create User Policy Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-create-user-policy",
  templateUrl: "./create-user-policy.component.html",
  styleUrls: ["./create-user-policy.component.css"],
})

// #=========== ---- =========== Create User Policy Component =========== ---- ===========# //
export class CreateUserPolicyComponent implements OnInit {
    userPolicyForm: FormGroup;
    userPolicy: any;

    roles: string[];

    modules: string[];

    // #=========== ---- =========== Create User Policy Component Constructor =========== ---- ===========# //
    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private userPolicyService: UserPolicyService,
    ) {}

    // #=========== ---- =========== Create User Policy Component ngOnInit =========== ---- ===========# //
    ngOnInit(): void {
        this.authenticationService.isLoggedInWithRedirect();

        const session = this.authenticationService.getSession();
        this.roles = session.company.roles;
        this.modules = session.company.modules;

        this.userPolicyForm = this.formBuilder.group({
            role: ["", Validators.required],
            modules: this.formBuilder.group({}),
        });

        const modulesFormGroup = this.userPolicyForm.get("modules") as FormGroup;
        this.modules.forEach((moduleVal: string) => {
            modulesFormGroup.addControl(
                moduleVal,
                this.formBuilder.control(false)
            );
        });
    }

    // #=========== ---- =========== Create User Policy Component onSubmit =========== ---- ===========# //
    onSubmit(): void {
        if (this.userPolicyForm.invalid) {
            this.snackBar.open("Please fill in all required fields", "Close", {
                verticalPosition: "top",
                duration: 2000,
            });
            return;
        }

        const userPolicyData = this.userPolicyForm.value;
        let checkedModules = userPolicyData.modules;

        checkedModules = Object.keys(checkedModules).filter(
            (moduleVal: string) => checkedModules[moduleVal]
        );

        userPolicyData.menuItems = checkedModules;
        delete userPolicyData.modules;

        this.userPolicyService
            .createUserPolicy(userPolicyData)
            .subscribe(
                (data: any) => {
                    if (data.message == "success") {
                        this.snackBar.open("User Policy created successfully", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                        this.router.navigate(["/user-policies"]);
                    } else {
                        this.snackBar.open("Error creating User Policy", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }
                },
                (error) => {
                    if (error.status === 400) {
                        this.snackBar.open("User Policy with this role already exists, update it instead", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    } else {
                        this.snackBar.open("Error updating User Policy, try again later", "Close", {
                            verticalPosition: "top",
                            duration: 2000,
                        });
                    }

                    this.router.navigate(["/user-policies"]);
                }
            );
    }
}   