// #=========== ---- =========== Import Angular Libraries =========== ---- ===========# //
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

// #=========== ---- =========== Import Services =========== ---- ===========# //
import { AuthenticationService } from "../../../services/authentication.service";
import { SpecialitiesService } from "../../../services/specialties.service";

// #=========== ---- =========== Create Speciality Component Decorator =========== ---- ===========# //
@Component({
  selector: "app-create-speciality",
  templateUrl: "./create-specialty.component.html",
  styleUrls: ["./create-specialty.component.css"],
})

// #=========== ---- =========== Create Speciality Component =========== ---- ===========# //
export class CreateSpecialityComponent implements OnInit {
  specialityForm: FormGroup;

  // #=========== ---- =========== Create Speciality Component Constructor =========== ---- ===========# //
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService,
    private specialitiesService: SpecialitiesService
  ) {}

  // #=========== ---- =========== Create Speciality Component ngOnInit =========== ---- ===========# //
  ngOnInit(): void {
    this.authenticationService.isLoggedInWithRedirect();

    this.specialityForm = this.formBuilder.group({
      abbreviation: ["", Validators.required],
      speciality: ["", Validators.required],
    });
  }

  // #=========== ---- =========== Create Speciality Component Methods =========== ---- ===========# //

  // #=========== ---- =========== Create Speciality Method =========== ---- ===========# //
  createSpeciality(): void {
    if (this.specialityForm.valid) {
      let speciality = this.specialityForm.value;

      this.specialitiesService.createSpecialty(speciality).subscribe(
        (data: any) => {
          if (data.message == "success") {
            this.snackBar.open("Speciality created successfully", "Close", {
              verticalPosition: "top",
              duration: 2000,
            });

            this.router.navigateByUrl("/specialities");
          }
        },
        (error) => {
          this.snackBar.open("Speciality creation failed.", "Close", {
            verticalPosition: "top",
            duration: 2000,
          });
        }
      );
    } else {
      this.snackBar.open(
        "Please fill in all required fields, marked red",
        "Close",
        {
          verticalPosition: "top",
          duration: 2000,
        }
      );
    }
  }
}
