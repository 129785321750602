import { Component, OnInit , ViewContainerRef, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment';

import { AuthenticationService } from '../../services/authentication.service';
import { CompanyService } from '../../services/company.service';
import { EmailService } from '../../services/email.service';
import { ChartService } from '../../services/chart.service';
import { PrintService } from '../../services/print.service';
import { ReportsService } from 'src/app/services/reports.service';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: "app-report-email",
  templateUrl: "./report-email.component.html",
  styleUrls: ["./report-email.component.css"],
})
export class ReportEmailComponent implements OnInit {
  @ViewChild("printTemplate", { read: true }) printTemplate: TemplateRef<any>;

  emailId: String = "";
  email: any;
  uniqueOpens = [];

  groupedByCountry;
  groupedByProvince;
  groupedByCity;
  groupedBySpeciality;
  groupedByClass;

  summaryChart: any;
  specialityChart: any;
  classChart: any;
  countryChart: any;
  provinceChart: any;
  cityChart: any;
  linksChart: any;

  linksHashtable = [];
  totalClicks = 0;
  uniqueClicks = 0;
  linksData = [];
  userClicksHashtable = [];
  userClicksData = [];
  chosenLink: string = "";
  notClicksData = [];

  dataSource: MatTableDataSource<any>; // Replace 'any' with the actual type of your data
  tableColumns: string[] = ["username", "openRate"]; // Replace with your actual column names
  selectedCountries:  string[] = null;
  selectedSpecialties:  string[] = null;
  countries: string[] = [
    "UAE",
    "Bahrain",
    "Egypt",
    "Iraq",
    "Jordan",
    "KSA",
    "Kuwait",
    "Oman",
    "Qatar",
    "LEB"
  ];
  specialties: string[] = [
    "",
    "GP",
    "IM",
    "ER",
    "Ortho",
    "Anasth",
    "Dentist",
    "Gastro",
    "Gastro",
    "Pharma",
    "ENT",
    "Nephro",
    "Gyna",
    "Uro",
    "Surg"
  ];
  isLoading: boolean = false;
  error: string | null = null;
  displayedColumns = [
    'name',
    'subject',
    'sentToCount',
    'openedCount',
    'uniqueClicks',
    'clicksCount',
    'createdAt',
    'sentAt',
    "status",
    'report',
  ]
  emailName: string = "";
  emailInfoHashTable = []
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public authService: AuthenticationService,
    public companyService: CompanyService,
    public emailService: EmailService,
    public reportService: ReportsService,
    public chartService: ChartService,
    public printService: PrintService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.authService.isLoggedInWithRedirect();
    this.fetchEmailData();
    this.authService.isLoggedInWithRedirect();
    const companyId = this.authService.getCompanyId();
    // Fetch the emails sent by the account
    this.emailService
      .findRelatedEmailsById(companyId, this.emailId)
      .subscribe(data => {
        // console.log(data)
        if (data.status === "success") {
          data.response.forEach(email => {
            this.emailInfoHashTable[email._id] = email
            this.emailInfoHashTable[email._id].clicks = email.totalNumberOfClicks;
            this.emailInfoHashTable[email._id].uniqueClicks = email.totalNumberOfUniqueClicks;
          })

          let emailsFinal = [];
          for (let email in this.emailInfoHashTable) {
            emailsFinal.push(this.emailInfoHashTable[email])
          }
          // console.log(emailsFinal)

          this.dataSource = new MatTableDataSource<any>(data.response);
          this.dataSource.data.map(email => {
            email.createdAt = moment(email.createdAt).format('DD-MM-YYYY, h:mm:ss a')
            if (email.sentAt) {
              email.sentAt = moment(email.sentAt).format('DD-MM-YYYY, h:mm:ss a');
            } else {
              // Handle the case where email.sentAt is undefined or null
              email.sentAt = "Not available";
            }
            return email;
          })
        }
      });
  }

  goToSendPage(email): void {
    this.router.navigateByUrl("/emails/send/" + email._id);
  }
  
  fetchEmailData() {
    // Clear existing data
    this.email = null;
    this.uniqueOpens = null;
    // Clear other variables if needed
    
    // Fetch updated data
    this.emailId = this.activatedRoute.snapshot.paramMap.get("emailId");
  
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService.find(this.emailId, options)
    .subscribe((data) => {
      if (data.status == "success") {
        this.emailName = data.response.name ? data.response.name : this.emailName;
        this.email = data.response;
        const rawOpenRate =
          (this.email.totalNumberOfUniqueOpens /
            this.email.totalNumberOfDelivered) *
          100;
        this.email.openRate = isFinite(rawOpenRate)
          ? rawOpenRate.toFixed(2)
          : 0;
        const rawClickRate =
          (this.email.totalNumberOfUniqueClicks /
            this.email.totalNumberOfDelivered) *
          100;
        this.email.clickRate = isFinite(rawClickRate)
          ? rawClickRate.toFixed(2)
          : 0;
  
        this.uniqueOpens = this.email.totalNumberOfUniqueOpens;
        
        setTimeout(() => {
          this.drawCharts();
        }, 2000);
      }
    },
    (error) => {
      this.isLoading = false;
      console.error('Error fetching email data:', error);
      this.error = 'Error fetching email data. Please try again later.';
      setTimeout(() => {
        this.error = null;
      }, 10000); // 10 sec
    },
    () => {
      this.isLoading = false;
    });
  }
  
  drawCharts(): void {
    const bounce = this.email.totalNumberOfBounced ? this.email.totalNumberOfBounced : 0;
    const dropped = this.email.totalNumberOfDropped ? this.email.totalNumberOfDropped : 0;

    this.chartService.drawBarChart(
      this.summaryChart,
      "summaryChart",
      [
        "Sent To",
        "Delivered",
        "Opens",
        "Unique Opens",
        "Clicks",
        "Unique Clicks",
        "Bounces",
      ],
      [
        this.email.totalNumberOfRecipients,
        this.email.totalNumberOfDelivered,
        this.email.totalNumberOfOpens,
        this.email.totalNumberOfUniqueOpens,
        this.email.totalNumberOfClicks,
        this.email.totalNumberOfUniqueClicks,
        bounce + dropped
      ],
      "Summary Chart"
    );

    // Mapping the linksHashtable to an array of objects
    this.linksData = Object.keys(this.linksHashtable).map((link) => ({
      link,
      totalClicks: this.linksHashtable[link].totalClicks,
      uniqueClicks: this.linksHashtable[link].uniqueClicks,
    }));

    // Calling the drawClicksBarChart method with the modified linksData
    // this.chartService.drawClicksBarChart(
    //   this.linksChart,
    //   "linksChart",
    //   this.linksData.map(link => link.link),
    //   this.linksData.map(link => link.totalClicks),
    //   this.linksData.map(link => link.uniqueClicks),
    //   "Links Chart"
    // );
  }

  public onPrintButtonClick(): void {
    let base64Array = [];
    let canvasElement = <HTMLCanvasElement>(
      document.getElementById("summaryChart")
    );
    base64Array.push(canvasElement.toDataURL());

    canvasElement = <HTMLCanvasElement>document.getElementById("rates");
    base64Array.push(canvasElement.toDataURL());
    const ratesTable = document.querySelector(".open-rate-table").outerHTML;
    fetch("assets/print-template.html")
      .then((response) => response.text())
      .then((html) => {
        const printContent = html
          .replace("{{emailName}}", this.emailName)
          .replace(
            "{{charts}}",
            base64Array.map((base64) => `<img src="${base64}">`).join("")
          )
          .replace("{{ratesTable}}", ratesTable);
        const printWin = window.open(
          "",
          "",
          "width=" + screen.availWidth + ",height=" + screen.availHeight
        );
        printWin.document.open();
        printWin.document.write(printContent);
        printWin.document.addEventListener(
          "load",
          function () {
            printWin.focus();
            printWin.print();
            setTimeout(function () {
              printWin.document.close();
              printWin.close();
            }, 901);
          },
          true
        );
      });
  }

  public setChosenLink(link) {
    this.chosenLink = link;
  }

  public groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  public getBouncedEmails() {
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService
      .getBouncedEmails(this.emailId, options)
      .subscribe((bouncedEmails) => {
        const columns = [
          [
            "FirstName",
            "LastName",
            "Email",
            "Reason",
            "Bounce Classification",
            "Class",
            "Specialty",
            "Country",
            "City",
            "Province",
            "Center",
          ],
        ];

        if (bouncedEmails.status === "success") {
          bouncedEmails.bounceData.forEach((eventData) => {
            // let info = user.info ? user.info.recipient : {};
            // let location = info.location ? info.location  : {};
            let user = eventData.recipient;
            let location = user.location ? user.location : {};
            columns.push([
              user.firstName,
              user.lastName,
              user.email,
              eventData.reason,
              user.bounce_classification,
              user.class,
              user.specialty,
              location.country,
              location.city,
              location.province,
              user.center,
            ]);
          });

          this.reportService.outputFileWithOneWorksheet(
            columns,
            `Bounced_Recipient_${this.emailName}`,
            "Bounced Emails"
          );
        }
      });
  }

  public getDeliveredEmails() {
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService
      .getDeliveredEmails(this.emailId, options)
      .subscribe((deliveredEmails) => {
        const columns = [
          [
            "FirstName",
            "LastName",
            "Email",
            "Class",
            "Specialty",
            "Country",
            "City",
            "Province",
            "Center",
          ],
        ];

        if (deliveredEmails.status === "success") {
          deliveredEmails.deliveredData.forEach((user) => {
            let recipient = user.recipient;
            let location = recipient ? recipient.location : {};
            columns.push([
              recipient.firstName,
              recipient.lastName,
              recipient.email,
              recipient.class,
              recipient.specialty,
              location.country,
              location.city,
              location.province,
              recipient.center,
            ]);
          });

          this.reportService.outputFileWithOneWorksheet(
            columns,
            `delivered_Recipient_${this.emailName}`,
            "delivered Emails"
          );
        }
      });
  }

  public getSentToEmails() {
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService
      .getSentToEmails(this.emailId, options)
      .subscribe((sentToEmails) => {
        const columns = [
          [
            "FirstName",
            "LastName",
            "Email",
            "Class",
            "Specialty",
            "Country",
            "City",
            "Province",
            "Center",
          ],
        ];
        if (sentToEmails.status === "success") {
          sentToEmails.data.forEach((sending) => {
            let user = sending.recipient;
            let location = user.location ? user.location : {};
            columns.push([
              user.firstName,
              user.lastName,
              user.email,
              user.class,
              user.specialty,
              location.country,
              location.city,
              location.province,
              user.center,
            ]);
          });

          this.reportService.outputFileWithOneWorksheet(
            columns,
            `SentTo_Recipient_${this.emailName}`,
            "SentTO Emails"
          );
        }
      });
  }

  applyFilters() {
    // Call fetchEmailData to fetch updated data based on filters
    this.isLoading = true;
    this.fetchEmailData();
  }

  public getOpenedEamils() {
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService
      .getOpenedEamils(this.emailId, options)
      .subscribe((opendEmails) => {
        const columns = [
          [
            "FirstName",
            "LastName",
            "Email",
            "Class",
            "Specialty",
            "Country",
            "City",
            "Province",
            "Center",
          ],
        ];
        if (opendEmails.status === "success") {
          opendEmails.data.forEach((recipient) => {
            let location = recipient.location ? recipient.location : {};
            columns.push([
              recipient.firstName,
              recipient.lastName,
              recipient.email,
              recipient.class,
              recipient.specialty,
              location.country,
              location.city,
              location.province,
              recipient.center,
            ]);
          });

          this.reportService.outputFileWithOneWorksheet(
            columns,
            `Opened_Recipient_${this.emailName}`,
            "Opened"
          );
        }
      });
  }
  public getNotOpenedEamils() {
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService
      .getNotOpenedEamils(this.emailId, options)
      .subscribe((notOpenedEmails) => {
        const columns = [
          [
            "FirstName",
            "LastName",
            "Email",
            "Class",
            "Specialty",
            "Country",
            "City",
            "Province",
            "Center",
          ],
        ];
        if (notOpenedEmails.status === "success") {
          notOpenedEmails.data.forEach((recipient) => {
            let location = recipient.location ? recipient.location : {};
            columns.push([
              recipient.firstName,
              recipient.lastName,
              recipient.email,
              recipient.class,
              recipient.specialty,
              location.country,
              location.city,
              location.province,
              recipient.center,
            ]);
          });

          this.reportService.outputFileWithOneWorksheet(
            columns,
            `NotOpend_Recipient_${this.emailName}`,
            "Not Opend"
          );
        }
      });
  }

  public getLinkClickedEmails() {
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService
      .getClickedEamils(this.emailId, options)
      .subscribe((res) => {
        const columns = [
          [
            "FirstName",
            "LastName",
            "Emails",
            "Class",
            "Speciality",
            "Country",
            "City",
            "Province",
            "Center",
          ],
        ];
        let allUsers = [];
        if (this.chosenLink === "" || !this.chosenLink) {
          const links = Object.keys(res.data.clickedEmails);
          for (const link of links) {
            allUsers = allUsers.concat(res.data.clickedEmails[link].users);
          }
        } else {
          allUsers = res.data.clickedEmails[this.chosenLink].users;
        }

        allUsers.forEach((linInfo) => {
          let location = linInfo.location ? linInfo.location : {};
          columns.push([
            linInfo.firstName,
            linInfo.lastName,
            linInfo.email,
            linInfo.class,
            linInfo.specialty,
            location.country,
            location.city,
            location.province,
            linInfo.center,
          ]);
        });
        this.reportService.outputFileWithOneWorksheet(
          columns,
          `Clicked_Recipient_${this.emailName}`,
          `Emails Clicked`
        );
      });
  }
  
  public getNotClickedEmails() {
    const options = {
      selectedCountries: this.selectedCountries,
      selectedSpecialties: this.selectedSpecialties
    }
    this.emailService
      .getEmailNotClicks(this.emailId, options, this.chosenLink)
      .subscribe((response) => {
        ({response : this.notClicksData} = response);
        const columns = [
          [
            "FirstName",
            "LastName",
            "Emails",
            "Class",
            "Specialty",
            "Country",
            "City",
            "Province",
            "Center",
          ],
        ];

        let users = this.notClicksData;
        users.forEach((user) => {
          let location = user.location ? user.location : {};
          columns.push([
            user.firstName,
            user.lastName,
            user.email,
            user.class,
            user.specialty,
            location.country,
            location.city,
            location.province,
            user.center,
          ]);
        });

        this.reportService.outputFileWithOneWorksheet(
          columns,
          `NotClick_Recipient_${this.emailName}`,
          "Emails"
        );
      });
  }
  goToReportage(email): void {
    const newUrl = "/emails/report/" + email._id;
    this.router.navigateByUrl(newUrl).then(() => {
      window.location.reload();
    });
  }
  
  printEmailReports() {
    let cols = [
      {
        title: 'Name',
        dataKey: 'name'
      },
      {
        title: "Subject",
        dataKey: "subject"
      },
      {
        title: "Total Number of Sends",
        dataKey: "totalNumberOfSends"
      },
      {
        title: "Total Number of Opens",
        dataKey: "totalNumberOfOpens"
      },
      {
        title: "Total Number of Unique Opens",
        dataKey: "totalNumberOfUniqueOpens"
      },
      {
        title: "Created At",
        dataKey: "createdAt"
      },
      {
        title: "Sent At",
        dataKey: "sentAt"
      }
    ]

    let rows = this.dataSource.data.map(email => {
      email.totalNumberOfSends = email.sentTo ? email.sentTo.length : 0;
      email.totalNumberOfOpens = email.totalNumberOfOpens ? email.totalNumberOfOpens : 0;
      email.totalNumberOfUniqueOpens = email.sentTo ? email.sentTo.filter(e => e.opened == true).length : 0;
      return email;
    });

    this.printService.printTable(
      'landscape',
      cols,
      rows,
      "Emails Report - " + moment().format('dddd, MMMM Do YYYY, h:mm:ss a'),
      "Emails-Report"
    );
  }
}
