import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private baseUrl: String = "http://104.248.203.173:3000/api/emails/";
  private baseUrlApi : String = environment.baseUrlV2Emails;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  sendEmails(
    emailId,
    emailBody,
    emailSubject,
    recipients,
    options
  ): Observable<any> {
    return this.http.post(
      this.baseUrlApi + "send",
      {
        emailId: emailId,
        companyId: this.authService.getCompanyId(),
        email_body: emailBody,
        subject: emailSubject,
        recipients: recipients,
        from: {
          email: options.fromEmail,
          name: options.fromName
        },
        options
      },
      { headers: { "x-access-token": this.authService.getToken() } }
    );
  }
  
  create(newEmail): Observable<any> {
    return this.http.post(
      this.baseUrlApi + "create", newEmail,
      { headers: { "x-access-token": this.authService.getToken() } }
    );
  }

  findByCompany(companyId): Observable<any> {
    return this.http.get(
      this.baseUrlApi + "get/by-company/" + companyId,
      { headers: { "x-access-token": this.authService.getToken() } }
    );
  }


  find(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}): Observable<any> {
    let httpParams = new HttpParams();
  
    // Add country parameter if provided
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }
  
    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }

    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken() }),
      params: httpParams
    };
  
    return this.http.get(
      this.baseUrlApi + "get/" + emailId + "",
      requestOptions
    );
  }
  
  findOne(emailId): Observable<any> {
    return this.http.get(
      this.baseUrlApi + "getOne/" + emailId,
      { headers: { "x-access-token": this.authService.getToken() } }
    );
  }

  getEmailNotClicks(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}, link): Observable<any> {
    let httpParams = new HttpParams();
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }

    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }
    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken(), "link": link }),
      params: httpParams
    };
    return this.http.get(
      this.baseUrlApi + "getNotClicks/" + this.authService.getCompanyId() + '/' + emailId,
      requestOptions
    );
  }

  getBouncedEmails(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}): Observable<any> {
    let httpParams = new HttpParams();
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }
  
    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }

    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken() }),
      params: httpParams
    };
    return this.http.get(
      this.baseUrlApi + "bounce/" + this.authService.getCompanyId() + '/' + emailId,
      requestOptions
    );
  }

  getDeliveredEmails(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}): Observable<any> {
    let httpParams = new HttpParams();
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }
  
    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }

    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken() }),
      params: httpParams
    };
    return this.http.get(
      this.baseUrlApi + "delivery/" + this.authService.getCompanyId() + '/' + emailId,
      requestOptions
    );
  }

  getSentToEmails(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}): Observable<any> {
    let httpParams = new HttpParams();
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }
  
    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }
    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken() }),
      params: httpParams
    };
    return this.http.get(
      this.baseUrlApi + "sentTo/" + this.authService.getCompanyId() + '/' + emailId,
      requestOptions
    );
  }

  update(emailId, newEmail): Observable<any> {
    return this.http.put(
      this.baseUrlApi + "update/" + emailId, newEmail,
      { headers: { "x-access-token": this.authService.getToken() } }
    );
  }

  getOpenedEamils(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}): Observable<any> {
    let httpParams = new HttpParams();
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }
  
    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }
    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken() }),
      params: httpParams
    };
    return this.http.get(
      this.baseUrlApi + "open/" + this.authService.getCompanyId() + '/' + emailId,
      requestOptions
    );
  }

  getNotOpenedEamils(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}): Observable<any> {
    let httpParams = new HttpParams();
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }
  
    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }
    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken() }),
      params: httpParams
    };
    return this.http.get(
      this.baseUrlApi + "notopen/" + this.authService.getCompanyId() + '/' + emailId,
      requestOptions
    );
  }

  getClickedEamils(emailId, params: { selectedCountries?: string[], selectedSpecialties?: string[] } = {}): Observable<any> {
    let httpParams = new HttpParams();
    if (params.selectedCountries && params.selectedCountries.length > 0) {
      const countriesParam = params.selectedCountries.join(',');
      httpParams = httpParams.set('country', countriesParam);
    }
  
    // Add specialty parameter if provided
    if (params.selectedSpecialties && params.selectedSpecialties.length > 0) {
      const specialtiesParam = params.selectedSpecialties.join(',');
      httpParams = httpParams.set('specialty', specialtiesParam);
    }
    let requestOptions = {
      headers: new HttpHeaders({ "x-access-token": this.authService.getToken() }),
      params: httpParams
    };
    return this.http.get(
      this.baseUrlApi + "click/" + this.authService.getCompanyId() + '/' + emailId,
      requestOptions
    );
  }

  findRelatedEmailsById(companyId, emailId): Observable<any> {
    return this.http.get(
      this.baseUrlApi + "realted-emails/" + companyId +"/"+emailId,
      { headers: { "x-access-token": this.authService.getToken() } }
    );
  }
}
